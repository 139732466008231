<template>
    <BasePopup v-if="isLoaded" closeType="stayOnPage" width="700" @clickToClose="clickToClose">
            <header>
                <font-awesome-icon icon="fa-solid fa-cart-arrow-up" style="color: #215eda;"/>
                <span v-if="route.name == 'productUpsellEdit'"  class="ms-2 align-middle">Editar cross-sell</span>
                <span v-else class="ms-2 align-middle">Novo cross-sell</span>
            </header>
            <div class="content">
                <form @submit.prevent="">
                    <div class="row">
                        <BaseSelect
                            class="col-12 mb-3"
                            id="id_product_sell"
                            label="Produto"
                            :items="allProducts"
                            placeholder="Selecione"
                            :page="route.name"
                            v-model="productStore.upsell.id_product_sell"
                        />

                        <BaseInput
                            class="col-12 mb-3"
                            label="Ordem de exibição *"
                            type="number"
                            id="sequence"
                            v-model="upsell.sequence"
                            :page="route.name"
                        />

                        <BaseSelect
                            class="col-12 mb-3"
                            label="Status *"
                            :items="statusList"
                            id="status"
                            v-model="upsell.status"
                            :page="route.name"
                        />

                        <BaseSelect
                            class="col-12 mb-3"
                            label="Forma de pagamento *"
                            :items="paymentMethodsList"
                            id="payment_method"
                            v-model="upsell.payment_method"
                            :page="route.name"
                        />
                        <BaseSelect
                            v-if="route.name === 'productUpsellCreate'"
                            class="col-12 mb-3"
                            label="Modelo"
                            :items="templatesList"
                            id="template"
                            v-model="upsell.id_template"
                            :page="route.name"
                        />
                    </div>
                    <BaseButton @click="submit" class="btn-primary w-100 mt-4">
                        Salvar
                    </BaseButton>
                </form>
            </div>
    </BasePopup>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { storeToRefs } from 'pinia';
import { useRoute, useRouter} from 'vue-router'
import { useProductStore } from "@/stores/products";
import { useGlobalStore } from '@/stores/global';

const productStore = useProductStore();
const { upsell }  = storeToRefs(productStore); 
const globalStore = useGlobalStore();

const route = useRoute();
const router = useRouter();

const isLoaded = ref(false)

const product_id = computed (function () {
    return route.params.id;
});

const statusList = {
    0: 'Desabilitado',
    1: 'Habilitado',
}

const paymentMethodsList = {
    'credit-card': 'Cartão de crédito',
    'pix': 'Pix',
}

const templatesList = ref({})

const allProducts = {};

onBeforeMount(() =>{
    for(let i = 0; i < productStore.productslist.length; i++){
        allProducts[productStore.productslist[i].id] = productStore.productslist[i].title  + [productStore.productslist[i].tag !== null ? ' ('+ productStore.productslist[i].tag +')' : ''];
    }

    if(route.name == 'productUpsellEdit'){
        globalStore.loader('show')
        productStore.tryUpsell(route.params.slug).then(() =>{
            isLoaded.value = true
            globalStore.loader('hide')
        })
    } else if(route.name == 'productUpsellCreate') {
        globalStore.cleanObject(productStore.upsell);
        productStore.tryUpsellsTemplates().then(() =>{
            for(let i = 0; i <  productStore.upsells_templates.length; i++){
                templatesList.value[productStore.upsells_templates[i].id] = productStore.upsells_templates[i].title
            }
        })
        isLoaded.value = true;
    }
})

let previousPage = router.options.history.state.back;

function clickToClose() {
    if(previousPage !== null){
        router.push(previousPage)
    } else{
        previousPage = route.path.substring(0, route.path.lastIndexOf("/"));
        router.push(previousPage)
    }
}

function submit(){
    globalStore.loader('show')
    if(route.name == 'productUpsellEdit'){

        let params = ['id_product_sell', 'sequence', 'status', 'payment_method'];

        productStore.tryUpdateUpsell(params, route.name).then(function(success) {
            if(success){
                useGlobalStore().successMessage('Cross-sell atualizado com sucesso');

                productStore.tryUpsells().then(function(success){
                    if(success){
                        globalStore.loader('hide');
                        router.push('/products/' + product_id.value + '/upsells')
                    } else{
                        globalStore.loader('hide');
                    }
                })
            }
        });
    } else if(route.name == 'productUpsellCreate') {
        
        let params = ['id_product_sell', 'sequence', 'status', 'payment_method', 'id_template'];

        productStore.tryCreateUpsell(params, route.name).then(function(success){
            if(success){
                useGlobalStore().successMessage('Cross-sell criado com sucesso');

                productStore.tryUpsells().then(function(success) {
                    if(success){
                        globalStore.loader('hide');
                        router.push('/products/' + product_id.value + '/upsells')
                    } else {
                        globalStore.loader('hide');
                    }
                });
            }else {
                globalStore.loader('hide');
            }
        })
    }
}

</script>