<template>
    <div class="container px-2 px-lg-4">
        <BaseHeader :title="title" removeSearchBar :redirectBack="previousPage">
            <div class="text-center" id="device_selector">
                <baseButton :class="'btn me-2 ' + [(device == 'desktop') ? 'btn-secondary' : 'btn-white']" @click="changeDevice('desktop')"><font-awesome-icon icon="fa-regular fa-desktop" class="me-1" /> Desktop</baseButton>
                <baseButton :class="'btn me-2 ' + [(device == 'mobile') ? 'btn-secondary' : 'btn-white']" @click="changeDevice('mobile')"><font-awesome-icon icon="fa-regular fa-mobile" class="me-1" /> Mobile</baseButton>
            </div>
            <div class="btn-group action">
                <baseButton class="btn-primary w-100" @click="submit('completed')" style="border-top-right-radius: 0!important; border-bottom-right-radius: 0!important;">
                    Salvar
                </baseButton>
                <button class="btn btn-primary dropdown-toggle save_template" type="button" data-bs-toggle="dropdown" aria-expanded="false" style="background-color: #215EDA;"></button>
                <ul class="dropdown-menu dropdown-menu-end">
                    <li class="dropdown-item"  @click="submit('template')"><a>Salvar como modelo</a></li>
                </ul>
            </div>
        </BaseHeader>

        <div>

        </div>
        <div :class="'box_content ' + device">
            
            <div class="upsell_container customize_item" @click.self="openDialogCustomization('upsell')">
                <div class="upsell_content" @click.self="openDialogCustomization('upsell')">
                    <div class="upsell_header customize_item" @click.self="openDialogCustomization('header')">
                        <h2 v-html="upsell.customization.header_h2" class="customize_item" @click.self="openDialogCustomization('header_h2')"></h2>
                    </div>

                    <div id="countdown_wrapper" class="customize_item" v-if="upsell.customization.enable_timer == 1"
                        @click.self="openDialogCustomization('timer_background')">
                        <div class="row" @click.self="openDialogCustomization('timer_background')">
                            <div class="col customize_item" @click="openDialogCustomization('timer_text')">
                                <h5>{{ upsell.customization.timer_text }}</h5>
                            </div>
                            <div class="col customize_item" @click="openDialogCustomization('timer_numbers')">
                                <div id="countdown" data-time="10">
                                    <span>
                                        <strong>00</strong>
                                        Horas
                                    </span>
                                    <span>
                                        <strong>00</strong>
                                        Minutos
                                    </span>
                                    <span>
                                        <strong>00</strong>
                                        Segundos
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="upsell_body">
                        <h4 class="customize_item" @click.self="openDialogCustomization('body_title')">{{ upsell.customization.body_title }}</h4>
                        <p v-html="upsell.customization.body_text" class="customize_item" @click.self="openDialogCustomization('body_text')"></p>
                    </div>
                    <div class="upsell_offer customize_item" @click.self="openDialogCustomization('offer')">
                        <div class="upsell_image customize_item" @click="openDialogCustomization('media')">
                            <div v-if="!upsell.customization.media_type || upsell.customization.media_type == 'image'">
                                <img v-if="upsell_image_preview" :src="upsell_image_preview" />
                                <img v-else-if="upsell.customization.image" :src="upsell.customization.image" />
                                <img v-else :src="upsell.image" />
                            </div>
                            <div v-else-if="upsell.customization.media_type == 'video'">
                                <VideoPlayer :url="upsell.customization.video" />
                            </div>
                        </div>
                        <div class="upsell_details" @click.self="openDialogCustomization('offer')">
                            <h5 class="customize_item" @click.self="openDialogCustomization('product_title')">{{ extractVariables(upsell.customization.product_title) }}</h5>
                            <div class="price_details" v-if="upsell.price_promotional">
                                <span class="normal_price customize_item" @click="openDialogCustomization('normal_price')"><strong>{{ extractVariables(upsell.customization.normal_price_text) }}</strong></span>
                            </div>

                            <div class="final_price">
                                <span class="customize_item" @click="openDialogCustomization('final_price')">{{ extractVariables(upsell.customization.final_price_text) }}</span>
                            </div>

                            <button class="upsell_button customize_item" @click="openDialogCustomization('order_button')">{{ upsell.customization.order_button_text }}</button>
                        </div>
                    </div>

                    <div class="cancel_upsell">
                        <button class="upsell_cancel_button customize_item" @click="openDialogCustomization('exit_button')">{{ upsell.customization.exit_button_text }}</button>
                    </div>

                    <div class="upsell_footer">
                        <p>Parcelamento disponível em até 12x.</p>
                        <p>Oferta válida apenas para clientes {{ product.store.title }}.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <BasePopup v-if="templatePopup" closeType="stayOnPage" width="400" @clickToClose="clickToClose">
        <template v-if="isLoading == false">
            <header>
                <font-awesome-icon icon="fa-solid fa-table-layout" style="color: #215eda;"/>
                <span v-if="route.params.slug == 'productCoproductorEdit'" class="ms-2 align-middle">Editar coprodutor</span>
                <span v-else class="ms-2 align-middle">Salvar modelo</span>
            </header>
            <form @submit.prevent="">
                <div class="row">
                    <BaseInput
                        class="col-12 mb-3"
                        id="title"
                        label="Titulo *"
                        v-model="productStore.upsells_template.title"
                        :page="route.name"
                    />
                </div>
                <BaseButton @click="submitTemplate" class="btn-primary w-100 mt-4">
                    Salvar
                </BaseButton>
            </form>
        </template>
    </BasePopup>

    <BaseRightDialog @emitCloseDialog="closeDialog" v-if="open_dialog">
        <template v-slot:header>
            <font-awesome-icon icon="fa-solid fa-table-layout" style="color: #215eda;" />
            <span class="ms-2 align-middle">Personalização</span>
        </template>
        <template v-slot:body>
            <div v-for="config in customizationComponents[customization_type]" :key="config.id">
                <component
                    v-if="!config.display_if || (config.display_if && config.display_if.split(':')[1] == upsell.customization[config.display_if.split(':')[0]])"
                    :is="config.component" :label="config.label"
                    v-model="upsell.customization[config.model]"
                    v-model:checked="upsell.customization[config.model]"
                    :id="config.id"
                    v-bind="config.props"
                    @base64="config.onBase64" />
            </div>
        </template>
    </BaseRightDialog>
</template>

<script setup>
import { useGlobalStore } from "@/stores/global";
import { useProductStore } from "@/stores/products";
import { storeToRefs } from "pinia";
import { computed, onBeforeMount, ref, shallowRef } from "vue";
import { useRoute, useRouter } from "vue-router";
import VideoPlayer from '@/components/ui/VideoPlayer';

const title = { title: 'Editar cross-sell', icon: 'fa-cart-arrow-up'};
const previousPage = ref(null);
const isLoading = ref(false);
const globalStore = useGlobalStore();
const router = useRouter();
const route = useRoute();

const templatePopup = shallowRef(null);

const product_id = computed(function () {
    return route.params.id;
});

const device = ref('desktop');

function changeDevice(type){
    device.value = type;
    closeDialog()
}

const productStore = useProductStore();
const { product, upsell } = storeToRefs(productStore);

onBeforeMount(async () => {
    if (!globalStore.hasPermissionTo('edit_product')) {
        return router.push('/403');
    }
    previousPage.value = router.options.history.state.back;

    globalStore.loader('show');

    if (!productStore.product.id) {
        await productStore.tryProduct(product_id.value);
    }

    await productStore.tryUpsell(route.params.slug);
    globalStore.loader('hide')
});

const customizationComponents = {
    upsell: [
        {
            component: 'BaseColorPicker',
            label: 'Cor do fundo',
            model: 'background_color',
            id: 'background_color',
        },
        {
            component: 'BaseSwitch',
            label: 'Timer de Escassez',
            model: 'enable_timer',
            id: 'enable_timer',
        }
    ],
    header: [
        {
            component: 'BaseColorPicker',
            label: 'Cor do fundo',
            model: 'header_background_color',
            id: 'header_background_color'
        }
    ],
    header_h2: [
        {
            component: 'BaseInput',
            label: 'Texto',
            model: 'header_h2',
            id: 'header_h2',
            props: {
                textarea: true
            }
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto',
            model: 'header_h2_color',
            id: 'header_h2_color'
        }
    ],
    body_title: [
        {
            component: 'BaseInput',
            label: 'Texto',
            model: 'body_title',
            id: 'body_title'
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto',
            model: 'body_title_color',
            id: 'body_title_color'
        }
    ],
    body_text: [
        {
            component: 'BaseInput',
            label: 'Texto',
            model: 'body_text',
            id: 'body_text',
            props: {
                textarea: true
            }
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto',
            model: 'body_text_color',
            id: 'body_text_color'
        }
    ],
    media: [
        {
            component: 'BaseSelect',
            label: 'Tipo de mídia',
            model: 'media_type',
            id: 'media_type',
            props: {
                items: {
                    'image': 'Imagem',
                    'video': 'Vídeo'
                },
                style: "display: none"
            }

        },
        {
            component: 'BaseFileUpload',
            label: 'Imagem',
            model: 'image',
            id: 'image',
            onBase64: upsellImage,
            props: {
                short: true,
            }
        },
        {
            component: 'BaseInput',
            label: 'Url do Vídeo',
            model: 'video',
            id: 'video',
            display_if: 'media_type:video'

        }
    ],
    product_title: [
        {
            component: 'BaseInput',
            label: 'Texto',
            model: 'product_title',
            id: 'product_title',
            props: {
                textarea: true
            }        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto',
            model: 'product_title_color',
            id: 'product_title_color'
        },
    ],
    order_button: [
        {
            component: 'BaseInput',
            label: 'Texto',
            model: 'order_button_text',
            id: 'order_button_text'
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do fundo',
            model: 'order_button_background_color',
            id: 'order_button_background_color'
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto',
            model: 'order_button_text_color',
            id: 'order_button_text_color'
        }
    ],
    exit_button: [
        {
            component: 'BaseInput',
            label: 'Texto',
            model: 'exit_button_text',
            id: 'exit_button_text'
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do fundo',
            model: 'exit_button_background_color',
            id: 'exit_button_background_color'
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto',
            model: 'exit_button_text_color',
            id: 'exit_button_text_color'
        }
    ],
    timer_numbers: [
        {
            component: 'BaseInput',
            label: 'Duração em minutos',
            model: 'timer_time',
            id: 'timer_time',
            type: 'text'
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do fundo',
            model: 'timer_numbers_background_color',
            id: 'timer_numbers_background_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor dos números',
            model: 'timer_numbers_text_color',
            id: 'timer_numbers_text_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor da legenda',
            model: 'timer_numbers_legend_color',
            id: 'timer_numbers_legend_color',
        }
    ],
    timer_background: [
        {
            component: 'BaseColorPicker',
            label: 'Cor do fundo',
            model: 'timer_background_color',
            id: 'timer_background_color',
        }
    ],
    timer_text: [
        {
            component: 'BaseInput',
            label: 'Texto',
            model: 'timer_text',
            id: 'timer_text',
            type: 'text'
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto',
            model: 'timer_text_color',
            id: 'timer_text_color',
        }
    ],

    offer: [
        {
            component: 'BaseColorPicker',
            label: 'Cor do fundo',
            model: 'offer_background_color',
            id: 'offer_background_color',
        },
    ],
    
    normal_price: [
        {
            component: 'BaseInput',
            label: 'Texto',
            model: 'normal_price_text',
            id: 'normal_price_text',
        },
    ],

    final_price: [
        {
            component: 'BaseInput',
            label: 'Texto',
            model: 'final_price_text',
            id: 'final_price_text',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto',
            model: 'final_price_text_color',
            id: 'final_price_text_color',
        }
    ],

};

const emit = defineEmits(['emitPreviewFile'])

const upsell_image_preview = ref(null);
function upsellImage(value) {
    upsell_image_preview.value = value;

    emit('emitPreviewFile', {
        'upsell_image': value,
    })
}

function submit(value){
    if(isLoading.value) {
        return;
    }

    globalStore.loader('show');
    isLoading.value = true;

    let params = ['customization'];

    if(value === 'completed'){
        productStore.tryUpdateUpsellCustomization(params, route.name).then(function(success) {
            if(success){
                globalStore.successMessage('Cross-sell atualizado com sucesso');
            }

            globalStore.loader('hide');
            isLoading.value = false;

            productStore.tryUpsells();
        })
    } else if(value === 'template'){
        delete globalStore.errors[route.name];
        productStore.upsells_template = {};
        productStore.upsells_template['title'] = null;
        templatePopup.value = true
        globalStore.loader('hide');
        isLoading.value = false;

    }
}

function submitTemplate(){
    globalStore.loader('show');
    productStore.upsells_template.customization = productStore.upsell.customization;
    let params = ['title', 'customization'];
    productStore.tryUpdateUpsellCustomizationTemplate(params, route.name).then(function(success) {
        if(success){
            globalStore.successMessage('Modelo de cross-sell criado com sucesso');  
            templatePopup.value = false;
            isLoading.value = false;
            globalStore.loader('hide');
            productStore.tryUpsells();
        }else{
            isLoading.value = false;
            globalStore.loader('hide');
            productStore.tryUpsells();
        }

    })
}

function clickToClose() {
    templatePopup.value = null;
}

const open_dialog = ref(false);
const customization_type = ref(false);

function openDialogCustomization(type) {
    customization_type.value = type;
    open_dialog.value = true;
}

function closeDialog() {
    open_dialog.value = false;
}

var index_variables = 0;

function extractVariables(string){
    if (productStore.upsell.id) {
        if(string == null || string == undefined){
            if(index_variables == 0){
                string = '{produto:titulo}'
                string = string.replace('{produto:titulo}', productStore.upsell.title);
                index_variables++
            } else if(index_variables == 1){
                string = '{produto:valor_antigo}'
                string = string.replace('{produto:valor_antigo}', globalStore.formatMoney(productStore.upsell.price));
                index_variables++
            } else if(index_variables == 2){
                string = '{produto:valor}'
                string = string.replace('{produto:valor}', globalStore.formatMoney(productStore.upsell.price_promotional ?? productStore.upsell.price));
                index_variables++
            }
            return;
        }
        string = string.replace('{produto:valor}', globalStore.formatMoney(productStore.upsell.price_promotional ?? productStore.upsell.price));
        string = string.replace('{produto:valor_antigo}', globalStore.formatMoney(productStore.upsell.price));
        string = string.replace('{produto:titulo}', productStore.upsell.title);
    }

    return string;
}
</script>

<style scoped>

.save_template{
    border: 0px;
    border-left: 1px #2750a3 solid;;
    border-radius: 0px 25px 25px 0px;
}

.save_template:hover{
    background-color: #2750a3 !important;
}

.dropdown-toggle:empty::after{
    margin: auto!important;
    font-size: 11px!important;
}

.upsell_container {
    background-color: v-bind('upsell.customization.background_color');
    text-align: center;
}

.upsell_header {
    background-color: v-bind('upsell.customization.header_background_color');
    color: #fff;
    padding: 40px;
}

.upsell_header h2 {
    color: v-bind('upsell.customization.header_h2_color') !important;
    white-space: pre-wrap;
    min-height: 38px;
}

.upsell_body {
    padding: 40px;
    max-width: 800px;
    margin: 0 auto;
    margin-top: 20px;
}

.upsell_body h4 {
    color: v-bind('upsell.customization.body_title_color') !important;
    margin-bottom: 10px;
    font-size: 30px;
    min-height: 36px;
}

.upsell_body p {
    color: v-bind('upsell.customization.body_text_color') !important;
    margin-bottom: 20px;
    font-size: 18px;
    white-space: pre-wrap;
    min-height: 27px;
}

.upsell_offer {
    background-color: v-bind('upsell.customization.offer_background_color');
    padding: 20px;
    border-radius: 10px;
    font-size: 20px;
    max-width: 700px;
    margin: 0 auto;
    border: 1px #d7d7d7 solid;
    margin-bottom: 20px;
}

.upsell_image {
    margin-bottom: 20px;
}

.upsell_image img {
    max-width: 100%;
    display: block;
    margin: 0 auto;
}

.upsell_details {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.upsell_details h5 {
    color: v-bind('upsell.customization.product_title_color');
    font-size: 25px;
    margin-bottom: 15px;
    display: inline-block;
    width: fit-content;
    white-space: pre-wrap;
    min-width: 200px;
    min-height: 30px;
}

.price_details {
    margin-bottom: 15px;
    display: inline-block;
    width: fit-content;
}

.normal_price {
    display: block;
    min-height: 28px;
}


.normal_price strong {
    text-decoration: line-through;
    font-weight: 500 !important;
}

.final_price {
    font-size: 1.2em;
    color: v-bind('upsell.customization.final_price_text_color');
    font-weight: 600;
    margin-bottom: 10px;
    min-height: 30px;
}

.upsell_button {
    background-color: v-bind('upsell.customization.order_button_background_color');
    color: v-bind('upsell.customization.order_button_text_color');
    padding: 20px 20px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    margin-top: 20px;
    font-weight: 500;
    width: 100%;
}

.upsell_button:hover {
    filter: brightness(85%);
}

.upsell_cancel_button {
    background-color: v-bind('upsell.customization.exit_button_background_color');
    color: v-bind('upsell.customization.exit_button_text_color');
    padding: 20px 20px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    font-weight: 500;
    width: 100%;
}

.upsell_cancel_button:hover {
    filter: brightness(85%);
}

.upsell_footer {
    padding-top: 20px;
    padding-bottom: 40px;
}

.upsell_footer p {
    font-size: 15px;
    color: #989898;
    margin-bottom: 0;
}

.cancel_upsell {
    max-width: 700px;
    margin: 0 auto;
    margin-bottom: 20px;
}

#countdown_wrapper {
    background-color: v-bind('upsell.customization.timer_background_color');
	padding: 15px 20px;
	text-align: center;
	width: 100%;
	box-sizing: border-box;
}

#countdown_wrapper .row {
	display: flex;
	vertical-align: middle;
	justify-content: center;
	align-items: center;
    flex-direction: column;
}

#countdown_wrapper .column:first-child {
	text-align: left;
}

#countdown_wrapper .btn {
    background: #00b000;
    float: right;
    color: #fff;
    padding: 9px 50px;
    margin-left: 10px;
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
    display: block;
    margin-top: 3px;
    border-radius: 4px;
}

#countdown_wrapper .container {
	margin: 0 auto;
	padding: 0;
}

#countdown {
	display: inline-block;
	font-size: 12px;
	color: #fff;
	font-weight: 500;
	height: 50px;
    margin-top: 15px;
}

#countdown span {
  display: inline-block;
  margin: 2px;
}

#countdown strong {
	background: #fff;
	color: #000;
	padding: 2px 15px;
	display: block;
	border-radius: 4px;
}

#countdown strong {
  font-size: 22px;
  font-weight: 800;
}


#countdown_wrapper h5 {
	font-size: 18px;
	margin: 0;
	color: #fff;
	font-weight: 500;
    min-height: 21px;
}

#countdown_wrapper h5 {
    color: v-bind('upsell.customization.timer_text_color');
}

#countdown_wrapper span {
    color: v-bind('upsell.customization.timer_numbers_legend_color');
}

#countdown_wrapper #countdown strong {
    background-color: v-bind('upsell.customization.timer_numbers_background_color');
    color: v-bind('upsell.customization.timer_numbers_text_color');
}

#countdown_wrapper .btn {
    background-color: v-bind('upsell.customization.timer_button_color');
    color: v-bind('upsell.customization.timer_text_button_color');
}

.mobile .upsell_content  {
    margin: 0 auto;
    max-width: 500px !important;;
}

.action{
    margin-left: 70px!important;
}

@media screen and (max-width: 992px){
    .action{
        margin-left: 0px!important;
    }
}

</style>