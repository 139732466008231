<template>
        <div class="filter_bar p-3">
            <div class="d-flex gap-2">
                <div v-if="props.removeSearchBar === false" class="filter item w-100">
                    <div class="d-flex">
                        <div class="item w-100">
                            <font-awesome-icon icon="fa-solid fa-magnifying-glass" id="search_icon" />
                            <BaseInput style="margin-bottom:0 !important" placeholder="Buscar" id="search" name="search" page="header" v-model="search" @input="debounceSearch" />
                        </div>
                    </div>
                </div>
                <slot></slot>
            </div>
        </div>

</template>

<script setup>
import { ref, onBeforeMount, onMounted, onBeforeUnmount } from 'vue';

const props = defineProps({
    title: {
        required: true,
        default: null
    },

    removeSearchBar: {
        type: Boolean,
        required: false,
        default: false,
    },

    initialSearch: {
        required: false,
        default: null
    },

    redirectBack: {
        required: false,
        default: null,
    },
});

function createBorderOnScroll() {
    let body = document.querySelector("body");
    if(window.scrollY != 0){
        body.classList.add('scrolling');
    }else {
        body.classList.remove('scrolling');
    }
}

const search = ref(null);

onBeforeMount(() => {
    if(props.initialSearch != null) {
        search.value = props.initialSearch;
    }
});

onMounted(() => {
    window.addEventListener("scroll", createBorderOnScroll, true);
});

onBeforeUnmount(() => {
    window.removeEventListener("scroll", createBorderOnScroll, true);
});

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();

const emit = defineEmits(["search"]);

let debounce = null;
function debounceSearch(event) {
    clearTimeout(debounce)
    debounce = setTimeout(() => {
        emit("search", event.target.value);
        globalStore.loader('show');
    }, 700)
}
</script>

<style>

.filter_bar {
    background: #fff;
    border: 1px #d7d7d7 solid;
    border-radius: 8px 8px 0px 0px;
    margin-bottom: -1px;
    position: relative;
}

.base-title .align {
    display: flex;
    align-items: center;
    min-height: 38px;
    max-width: 1350px;
    margin: 0 auto;
}

.base-title .space-between {
    justify-content: space-between;
}

.base-title .item {
    flex: 1;
    margin-left: 20px;
}

.base-title .title {
    flex: inherit;
    margin-left: 0px;
}

.base-title h2 {
    display: inline-block;
    font-size: 18px;
    margin: 0;
    color: #414951;
    font-weight: 600;
}

.base-title h2 .icon {
    margin-right: 10px;
    color:#215eda;
}

.base-title .action {
    min-width: 100px;
    flex: 0 0 120px;
    margin-left: 10px;
    justify-content: end;
}

.base-title .badget {
    position: relative;
    top: -2px;
    margin-left: 10px;
}

body.scrolling .base-title {
    border-bottom: 1px solid #d7d7d7;
}

.back_arrow{
    cursor: pointer;
    border-radius: 50%;
    padding: 7px 8px;
}

.back_arrow:hover{
    box-shadow: inset 0px 0px 50px rgb(0 0 0 / 20%);
}

.back_arrow_title{
    position: relative;
    top: -7px;
}

#search_icon {
    margin-top: 12px;
    margin-left: 15px;
    position: absolute;
    z-index: 1;
    color: #767676;
}

#search {
    padding-left: 35px;
}

@media screen and (max-width: 992px){

    .base-title{
        position: relative;
        padding-left: 0px;
        text-align: center;
        padding-bottom: 1rem;
        border-bottom: 0px !important;
        top: 0;
    }

    .base-title .align{
        display: block;
    }

    .base-title .item, .base-title .btn-group{
        width: 100%;
        margin: 8px 0px;
    }

    .space-base-title {
        display: none;
    }

}
</style>