<template>
    <div class="filter_container">
        <div class="collapse_items">
            <div class="collapse_item">
                <div class="collapse_title">
                    <a data-bs-toggle="collapse" href="#collapse_tags" role="button" :aria-expanded="hasTagFilterSelected" aria-controls="collapseExample">
                        <span class="opened_collapse">
                            <font-awesome-icon icon="fas fa-angle-down"/>
                        </span>
                        <span class="closed_collapse">
                            <font-awesome-icon icon="fas fa-angle-right" />
                        </span>
                        <span>Tags</span>
                    </a>
                </div>
                <div :class="'collapse ' + [hasTagFilterSelected ? 'show' : '']" id="collapse_tags">
                    <BaseSelect placeholder="Selecione" :items="formatedTagList" v-model="filter.tags" id="tag" page="products" multi />
                </div>
            </div>
            <div class="collapse_item">
                <div class="collapse_title">
                    <a data-bs-toggle="collapse" href="#collapse_status" role="button" :aria-expanded="hasStatusFilterSelected" aria-controls="collapseExample">
                        <span class="opened_collapse">
                            <font-awesome-icon icon="fas fa-angle-down"/>
                        </span>
                        <span class="closed_collapse">
                            <font-awesome-icon icon="fas fa-angle-right" />
                        </span>
                        <span>Status</span>
                    </a>
                </div>
                <div :class="'collapse ' + [hasStatusFilterSelected ? 'show' : '']" id="collapse_status">
                    <BaseSelect placeholder="Selecione" :items="formatedStatusList" v-model="filter.status" id="status" page="products" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onBeforeMount, watch, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useProductStore } from '@/stores/products';
import { useGlobalStore } from '@/stores/global'

const productStore = useProductStore();
const { tagsList } = storeToRefs(productStore);

const globalStore = useGlobalStore();

const formatedTagList = computed (function () {
    let list = {};
    Object.keys(tagsList.value).forEach((key) => {
        let currentList = {

            ...list,
            [tagsList.value[key].tag]: tagsList.value[key].tag
        };
        list = currentList;
    });

    return list;
});


const formatedStatusList = ref({
    ' ': 'Todos',
    'disabled': 'Desativado',
    'published': 'Publicado',
    'draft': 'Rascunho',
    'analyzing': 'Em análise',
})

const filter = ref({
    tags:[],
    status: ' '
    
});

const props = defineProps({
    initialFilters: {
        required: false,
        default: null
    }
});

let ignoreOnloadEmit = true;

let hasTagFilterSelected = false;
let hasStatusFilterSelected = false;

onBeforeMount(() => {
    if(props.initialFilters) {
        ignoreOnloadEmit = true;
        let filters = props.initialFilters;
        

        for(var i=0; i <= 1 ; i++) {
            let currentForFilter = (i == 0) ? 'tags' : '';

            if((filters[currentForFilter]) && filters[currentForFilter].length != 0) {
                Object.keys(filter.value[currentForFilter]).forEach((key) => {

                    if((filters[currentForFilter]) && filters[currentForFilter].constructor === Array) {

                        if (!filters[currentForFilter].includes(key)) {
                            filter.value[currentForFilter][key] = false;
                        }
                    }else {
                        if(key != filters[currentForFilter])  {
                            filter.value[currentForFilter][key] = false;
                        }
                    }
                });
            }
        }


        if(filters['tags']) {
            filter.value['tags'] = filters['tags'];
            hasTagFilterSelected = true;
        }
    
        if(filters['status']) {

            filter.value['status'] = filters['status'];
            hasStatusFilterSelected = true;
        }

        updateFiltersCount();

    }
});

const emit = defineEmits(["filters", "filtersQty"]);

watch(() => filter, (e) => {
    let status = [];
    Object.keys(e.value.status).forEach((key) => {
        if(e.value.status[key] == true) {
            status.push(key);
        }
    });

    if(status.length === Object.keys(filter.value.status).length) {
        status = null;
    }
    debounceFilter(e.value.tags, e.value.status);

},{ deep: true })

let debounce = null;
function debounceFilter(tags, status) {
    if(ignoreOnloadEmit == false) {
        globalStore.loader('show');
    }

    clearTimeout(debounce)

    debounce = setTimeout(() => {
        if(ignoreOnloadEmit == false) {
            updateFiltersCount();
            emit("filters", {
                'tags': tags,
                'status': status,
            });
        }
        if(ignoreOnloadEmit == true) {
            ignoreOnloadEmit = false;
        }
    }, 700)
}

function updateFiltersCount() {

    let count = 0;

    Object.keys(filter.value).forEach((key) => {
        
        if(key == 'tags') { 
            if(filter.value[key] != null && filter.value[key].length) {
                count = count + 1;
            }

        }else if(key == 'status') {
            if(filter.value[key] != ' ') {
                count = count + 1;
            }

        }
    });

    emit("filtersQty", count);
}
</script>
<style scoped>

:deep(.select-box){
    position: fixed!important;
}

</style>