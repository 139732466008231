<template>
    <div class="container px-2 px-lg-4">
        <BaseHeader :title="title">
        </BaseHeader>
        <BaseFilter removeSearchBar>
            <div class="item action">
                <BaseButton class="btn-filter btn-primary w-100" data-bs-toggle="dropdown" ariahashpopup="true" aria-expanded="true" data-bs-auto-close="outside">
                    <font-awesome-icon icon="far fa-filter" />
                    Filtros
                    <span class="filters_qty_active">{{ totalFilters }}</span>
                </BaseButton>
                <div v-if="isLoading != true" class="dropdown-menu dropdown-menu-end py-0"  aria-labelledby="dropdownMenu">
                    <RefundsFilter @filters="filters" :initialFilters="initialFilters" @filtersQty="filtersQty" />
                </div>
            </div>
        </BaseFilter>
        <BaseTable class="no_top_radius" :columns="tableColumns" :paginate="paginate" :isLoading="isLoading" :qty="refundStore.refunds.length"  @changePage="changePage">
            <tr v-for="(refund, key) in refundStore.refunds" :key="key">
                <td width="10">{{ refund.id_order }}</td>
                <td>{{ (refund.author_type == 'productor') ? refund.account.name : refund.client.name }}</td>
                <td>{{ globalStore.formatMoney(refund.total) }}</td>
                <td><BaseBadget :text="refund.status.name" :color="refund.status.color" /></td>
                <td>{{ globalStore.formatDate(refund.order_created_at) }}</td>
                <td>{{ globalStore.formatDate(refund.created_at) }}</td>
                <td><BaseStoreDot :color="refund.store.color" :title="refund.store.title" /></td>
                <td class="actions">
                    <a :href="'/refunds/' + refund.id" @click.prevent="watchRefund(refund.id)" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Acompanhar"><font-awesome-icon icon="fas fa-edit" /></a>
                </td>
            </tr>
        </BaseTable>
    </div>
</template>
<script setup>
const title = {title: 'Reembolsos', icon: 'fa-badge-dollar'};
import { ref, onBeforeMount, watch } from 'vue';
import { storeToRefs } from 'pinia';
import RefundsFilter from '@/components/includes/refunds/RefundsFilter'

import { useRefundStore } from '@/stores/refund';
const refundStore = useRefundStore();
const { paginate } = storeToRefs(refundStore);

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();
const { defaultStore } = storeToRefs(globalStore);

const tableColumns = ['Pedido', 'Solicitante', 'Total', 'Status', 'Data da compra', 'Data da solicitação', 'Loja'];

const isLoading = ref(false);

import { useRouter, useRoute } from 'vue-router';
import BaseFilter from '@/components/ui/BaseFilter.vue';
const route = useRoute();
const router = useRouter();

let initialFilters = null;
const totalFilters = ref(0);

onBeforeMount(() => {
    if(!globalStore.hasPermissionTo('refunds')) {
        return router.push('/403');
    }

    if(refundStore.refunds.length == undefined){
        isLoading.value = true;
    }

    if(route.query.page) {
        refundStore.refunds_filters.page = route.query.page;
    }

    if(route.query.status) {
        Object.keys(refundStore.refunds_filters).forEach((key) => {
            if(key === 'status'){
                if(typeof route.query[key] === 'string'){
                    let lowerRouteQuery = route.query[key].toLowerCase()
                    route.query[key] = lowerRouteQuery;
                    refundStore.refunds_filters[key] = [];
                    refundStore.refunds_filters[key].push(route.query[key])
                } else {
                    refundStore.refunds_filters[key] = [];
                    for(let i = 0; i < route.query[key].length; i++){
                        refundStore.refunds_filters[key].push(route.query[key][i].toLowerCase())
                    }
                }
            } else if (key === 'page'){
                refundStore.refunds_filters[key] = route.query.page;
            }
        })
        initialFilters = refundStore.refunds_filters;
    }

    if(refundStore.refunds_filters){
        Object.keys(refundStore.refunds_filters).forEach((key) => {
            let param = refundStore.refunds_filters[key];
            refundStore.refunds_filters[key] = (param == undefined) ? null : param;

            if(key == 'status') {
                if((refundStore.refunds_filters[key]) && refundStore.refunds_filters[key].constructor !== Array) {
                    let currentThing = refundStore.refunds_filters[key];
                    refundStore.refunds_filters[key] = [];
                    refundStore.refunds_filters[key].push(currentThing);
                }
             }else if(key === 'page'){
                let currentThing = refundStore.refunds_filters[key];
                refundStore.refunds_filters[key]= currentThing;
             }
        });
        initialFilters = refundStore.refunds_filters;
    }

    loadRefunds().then(() => {
        isLoading.value = false;
    });
})

async function loadRefunds() {
    let usedParams = {};
    Object.keys(refundStore.refunds_filters).forEach((key) => {
        if(refundStore.refunds_filters[key] != null && refundStore.refunds_filters[key] != '') {
            let currentParams = {
                ...usedParams,
                [key]: refundStore.refunds_filters[key]
            };
            usedParams = currentParams;
        }
    });
    router.replace({query: usedParams});
    await refundStore.tryRefunds(refundStore.refunds_filters)
    globalStore.loader('hide');
}

let isChangingPage = false;
async function changePage(p) {
    if(isChangingPage) return;
    refundStore.refunds_filters.page = p;
    globalStore.loader('show');
    isChangingPage = true;
    loadRefunds().then(() => {
        isChangingPage = false;
        window.scrollTo({ top:0, left:0, behavior: "instant"});
    });
}

let loadDefaultStore = false;
watch(defaultStore, function () {
    if(!loadDefaultStore) {
        loadDefaultStore = true;
        refundStore.refunds_filters.page = null;
        loadRefunds().then(()=> {
            globalStore.loader('hide');
            loadDefaultStore = false;
        });
    }
});

function watchRefund(id){
    router.push('/refunds/' + id);
}

function filtersQty(r) {
    totalFilters.value = r;
}

function filters(r) {
    Object.keys(r).forEach((key) => {
        refundStore.refunds_filters[key] = r[key];
    });
    globalStore.loader('show');
    loadRefunds();
}
</script>

<style scoped>
.filters_qty_active{
    background-color: #5986e2;
    padding: 0px 6px;
    font-size: 12px;
    color: white;
    border-radius: 40px;
    margin-left: 9px;
}
</style>