import { defineStore } from "pinia";
import qs from "qs";
import Api from "@/api";

import { useGlobalStore } from '@/stores/global';

// TO ACCESS ROUTER ON PINIA
// this.router. ...
// TO ACCESS ROUTER ON PINIA

export const useStoreStore = defineStore('store', {

    state: () => ({
        stores: {},
        store: {
            meta: {
            },
            status: null,
        },
        all_warranties: {},
        paginate: {},
        coupons: {},
        coupon: {},
        products: {},
        pages: {},
        page: {},
        trackers:{},
        tracker: {
            content: {}
        },
        webhooks: {},
        webhook: {
            events: {},
        },
        checkouts: {},
        checkout: {
            customization: {},
            product_demo: {},
        },
        testsAb: {},
        testAb: {
            title: null,
            id_product: null,
            options: [],
        },
        store_checkouts: {},
        managers: {},
        manager: {},
        referrals: {},
        referralslist: {},
        referralsinvites: {},
        referral: {},
        referralmanagers: {},
        shipping_companies: {},
        shipping_company: {},
        shipping_methods: {},
        shipping_method: {},
        integrations: {},
        integration: {},
        payments: {},
        payment: {},
    }),

    actions: {

        async tryStores(params = null) {
            try {
                const response = await Api.get('stores', {params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});

                this.stores = response.data.data;
                this.paginate = response.data.meta;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryStore(id) {
            try {
                const response = await Api.get('stores/' + id);
                this.store = response.data.data.store;
                this.coupons = response.data.data.coupons;
                this.trackers = response.data.data.trackers;
                this.webhooks = response.data.data.webhooks;
                this.checkouts = response.data.data.checkouts;
                this.products = response.data.data.products;
                this.managers = response.data.data.managers;
                this.payments = response.data.data.payments;
                this.integrations = response.data.data.integrations;
                this.pages = response.data.data.pages;
                this.all_warranties = response.data.data.all_warranties;
                this.shipping_companies = response.data.data.shipping_companies;
                this.shipping_methods = response.data.data.shipping_methods;
                this.referrals = response.data.data.affiliation;
                this.referralslist = response.data.data.affiliation.referrals;
                this.referralsinvites = response.data.data.affiliation.invitations
                this.testsAb = response.data.data.tests_ab;
                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryUpdateStore(params, page) {

            let formData = useGlobalStore().getOnlyTabsParams(params, this.store);
            formData['_method'] = 'put';
            
            try {
                await Api.post('stores/' + this.store.id + '/', formData);

                useGlobalStore().successMessage('Loja atualizada com sucesso');

                return true;
            }
            catch (error) {
                useGlobalStore().tryIncomingErrors(error, page, params);
                return false;
            }
        },

        async tryGetWarranty() {
            try {
                const response = await Api.get('stores/warranty');
                this.all_warranties = response.data.data;

                return true;
            }
            catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryCreateStore(params, page) {
            try {
                let formData = useGlobalStore().getOnlyParams(params, this.store);

                await Api.post('stores', formData);

                useGlobalStore().successMessage('Loja cadastrada com sucesso');

                return true;
            }
            catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryDeleteStore(id) {
            try {
                await Api.delete('stores/' + id);
                await this.tryStores();
                useGlobalStore().successMessage('Loja removida com sucesso');
            }catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        //INTEGRAÇÕES
        //INTEGRAÇÕES

        async tryIntegrations(){
            try{
                const response = await Api.get('/stores/' + this.store.id + '/integrations/')
                this.integrations = response.data.data;
            } catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryIntegration(slug){
            try{
                this.integration = useGlobalStore().getObjectBySlug(this.integrations, slug);
            } catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryUpdateIntegration(store_id, params, page){
            const formData = new FormData();
            for (let key in params) {
                if(params[key]) {
                    if(key == 'content') {
                        for(key in params['content']){
                            if(params['content'][key] != null && params['content'][key] != 'null'){
                                formData.append('content[' + key +']', params['content'][key]);
                            } else {
                                formData.append('content[' + key +']', '');
                            }

                        }
                    }else {
                        if(params[key] != null){
                            formData.append(key, params[key]);
                        } else {
                            formData.append(key, '')
                        }
                    }
                }
            }

            formData.append('_method', 'put')

            try{
                await Api.post('/stores/' + store_id + '/integrations/' + this.integration.id, formData)
                useGlobalStore().successMessage('Integração atualizada com sucesso')
                return true;
            } catch(error){
                useGlobalStore().tryIncomingErrors(error, page)
                return false;
            }
        },

        //PAYMENTS
        //PAYMENTS

        async tryPayments(){
            try{
                const response = await Api.get('/stores/' + this.store.id + '/payments/')
                this.payments = response.data.data;

                return true;
            }
            catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryPayment(slug){
            try{
                let payment = useGlobalStore().getObjectBySlug(this.payments, slug);
                if(payment){
                    this.payment = payment;
                }else {
                    const response = await Api.get('/stores/' + this.store.id + '/payments/' + slug)
                    this.payment = response.data.data;
                }

                return true;
            }
            catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryUpdatePayment(id, params, page) {
            try {
                let formData = useGlobalStore().getOnlyParams(params, this.payment);

                formData['_method'] = 'put';

                await Api.post('stores/' + this.store.id + '/payments/' + id, formData);
                
                useGlobalStore().successMessage('Metodo de pagamento atualizado com sucesso');

                return true;
            }
            catch (error) {
                useGlobalStore().loader('hide')
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        //CUPOM
        //CUPOM

        async tryCoupons(){
            try{
                const response = await Api.get('/stores/' + this.store.id + '/coupons/')
                this.coupons = response.data.data;
            }catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryCoupon(id, id_store){
            try{                
                let coupon = useGlobalStore().getObjectById(this.coupons, id);

                if(coupon){
                    this.coupon = coupon;
                }else {
                    const response = await Api.get('/stores/' + id_store + '/coupons/' + id)
                    this.coupon = response.data.data;
                }

                return true;
            }
            catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },
        
        async tryStoreProducts(id){
            try{
                const response = await Api.get('/products/list?id_store=' + id )
                this.products = response.data.data;
                
                return true;
            }
            catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryCreateCoupon(params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.coupon);
                await Api.post('/stores/' + this.store.id + '/coupons/', formData);

                useGlobalStore().successMessage('Cupom criado com sucesso');

                return true;
            }
            catch(error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryUpdateCoupon(id, params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.coupon);

                formData['_method'] = 'put';

                await Api.post('/stores/' + this.store.id + '/coupons/' + id, formData);

                useGlobalStore().successMessage('Cupom atualizado com sucesso');

                return true;
            }
            catch(error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryDeleteCoupon(id){
            try{
                await Api.delete('stores/' + this.store.id + '/coupons/' + id);

                useGlobalStore().successMessage('Cupom removido com sucesso');

                return true;
            }
            catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        //PÁGINAS
        //PÁGINAS

        async tryPages(){
            try{
                const response = await Api.get('/stores/' + this.store.id + '/pages')
                this.pages = response.data.data;

                return true;
            }
            catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryPage(id, id_store){
            try{                
                let page = useGlobalStore().getObjectById(this.pages, id);
    
                if(page){
                    this.page = page;
                }else {
                    const response = await Api.get('/stores/' + id_store + '/pages/' + id)
                    this.page = response.data.data;
                }

                return true;
            }
            catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryCreatePage(params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.page);

                await Api.post('/stores/' + this.store.id + '/pages/', formData);
                useGlobalStore().successMessage('Página criada com sucesso');

                return true;
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryUpdatePage(params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.page);

                formData['_method'] = 'put';

                await Api.post('/stores/' + this.store.id + '/pages/' + this.page.id, formData);
                useGlobalStore().successMessage('Página atualizada com sucesso');

                return true;
            }
             catch (error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryDeletePage(id){
            try{
                await Api.delete('stores/' + this.store.id + '/pages/' + id);

                useGlobalStore().successMessage('Página removida com sucesso');

                return true;
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        //EVENTOS
        //EVENTOS

        async tryTrackers(){
            try{
                const response = await Api.get('/stores/' + this.store.id + '/trackers')
                this.trackers = response.data.data;

                return true;
            }
            catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryTracker(id, id_store){
            try{                

                let tracker = useGlobalStore().getObjectById(this.trackers, id);

                if(tracker){
                    this.tracker = tracker;
                }else {
                    const response_tracker = await Api.get('stores/' + id_store + '/trackers/' + id);
                    this.tracker = response_tracker.data.data;
                }

            }
            catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryCreateTracker(params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.tracker);

                await Api.post('/stores/' + this.store.id + '/trackers/', formData);
                useGlobalStore().successMessage('Rastreamento criado com sucesso');

                return true;
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryUpdateTracker(params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.tracker);

                formData['_method'] = 'put';

                await Api.post('/stores/' + this.store.id + '/trackers/' + this.tracker.id, formData);
                useGlobalStore().successMessage('Rastreamento atualizado com sucesso');

                return true;
            }
             catch (error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryDeleteTracker(id){
            try{
                await Api.delete('stores/' + this.store.id + '/trackers/' + id);

                useGlobalStore().successMessage('Rastreamento removido com sucesso');

                return true;
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        //WEBHOOKS
        //WEBHOOKS

        async tryWebhooks(){
            try{
                const response = await Api.get('/stores/' + this.store.id + '/webhooks/')
                this.webhooks = response.data.data;

                return true;
            }
            catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryWebhook(id, id_store){
            try{
                let webhook = useGlobalStore().getObjectById(this.webhooks, id);
    
                if(webhook){
                    this.webhook = webhook;
                }else {
                    const response = await Api.get('/stores/' + id_store + '/webhooks/' + id)
                    this.webhook = response.data.data;
                }

                return true;
            }
            catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryCreateWebhook(params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.webhook);

                await Api.post('/stores/' + this.store.id + '/webhooks/', formData);

                useGlobalStore().successMessage('Webhook criado com sucesso');

                return true;
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryUpdateWebhook(params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.webhook);

                formData['_method'] = 'put';

                await Api.post('/stores/' + this.store.id + '/webhooks/' + this.webhook.id, formData);

                useGlobalStore().successMessage('Webhook atualizado com sucesso');

                return true;
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryDeleteWebhook(id){
            try{
                await Api.delete('stores/' + this.store.id + '/webhooks/' + id);

                useGlobalStore().successMessage('Webhook removido com sucesso');

                return true;
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        //CHECKOUTS
        //CHECKOUTS

        async tryCheckouts(){
            try{
                const response = await Api.get('/stores/' + this.store.id + '/checkouts/')
                this.checkouts = response.data.data;

                return true;
            }
            catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryCheckout(id){
            try{
                let checkout = useGlobalStore().getObjectById(this.checkouts, id);
    
                if(checkout){
                    this.checkout = checkout;
                }else {
                    const response = await Api.get('/stores/' + this.store.id + '/checkouts/' + id)
                    this.checkout = response.data.data;
                }
            }
            catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryCreateCheckout(params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.checkout);

                await Api.post('/stores/' + this.store.id + '/checkouts/', formData);

                return true;
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryUpdateCheckout(params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.checkout);

                formData['_method'] = 'put';

                await Api.post('/stores/' + this.store.id + '/checkouts/' + this.checkout.id, formData);

                return true;
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryUpdateCheckoutCustumization(params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.checkout);


                formData['_method'] = 'put';

                await Api.post('/stores/' + this.store.id + '/checkouts/' + this.checkout.id + '/customization', formData);

                return true;
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryDuplicateCheckout(checkout_id){
            try{
                const response = await Api.get('/stores/'+ this.store.id +'/checkouts/' + checkout_id  + '/duplicate');

                useGlobalStore().successMessage('Checkout duplicado com sucesso');

                return response.data.data.id;
            }
        catch (error) {
            useGlobalStore().tryIncomingErrors(error);
            return false;
        }
        },

        async tryDeleteCheckout(id){
            try{
                await Api.delete('stores/' + this.store.id + '/checkouts/' + id);

                useGlobalStore().successMessage('Checkout removido com sucesso');

                return true;
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        //TESTES A/B
        //TESTES A/B

        async tryStoresCheckouts(){
            try {
                const response_store_checkouts = await Api.get('stores/' + this.store.id + '/checkouts/'); 
                this.store_checkouts = response_store_checkouts.data.data;

                return true;
            }
            catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryTestsAb(){
            try {
                const response_testsAb = await Api.get('stores/' + this.store.id + '/tests-ab/');
                this.testsAb = response_testsAb.data.data;

                return true;
            }
            catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryTestAb(id){
            try {
                let testAb = useGlobalStore().getObjectById(this.testsAb, id);
                if(testAb){
                    this.testAb = testAb;
                    this.testAb.update_options = this.testAb.options;
                }else {
                    const response_testAb = await Api.get('stores/' + this.store.id + '/tests-ab/' + id);
                    this.testAb = response_testAb.data.data;
                    this.testAb.update_options = this.testAb.options;
                }

                return true;
            }
            catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },
        
        async tryCreateTestAb(id, params, page){
            try {
                let formData = useGlobalStore().getOnlyParams(params, this.testAb);

                await Api.post('stores/' + id + '/tests-ab', formData);
            
                useGlobalStore().successMessage('Teste A/B criado com sucesso');
                
                return true;
            }
            catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },
            
        async tryDeleteTestsAb(testAb_id) {
            try {
                await Api.delete('stores/' + this.store.id + '/tests-ab/' + testAb_id);

                useGlobalStore().successMessage('Teste A/B removido com sucesso');

                return true;
            }
            catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryUpdateTestAb(params, page){
            try {
                let formData = useGlobalStore().getOnlyParams(params, this.testAb);
                formData['_method'] = 'put';

                await Api.post('stores/'+ this.store.id + '/tests-ab/' + this.testAb.id , formData);

                useGlobalStore().successMessage('Teste A/B atualizado com sucesso');

                return true;
            }
            catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        //GERENTES
        //GERENTES

        async tryManagers(){
            try{
                const response = await Api.get('/stores/' + this.store.id + '/managers/')
                this.managers = response.data.data;
            }catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryManager(id){
            try{
                const response = await Api.get('/stores/' +  this.store.id + '/managers/' + id)
                this.manager = response.data.data;
            }catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryCreateManager(params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.manager);

                await Api.post('/stores/' +  this.store.id + '/managers/', formData);

                useGlobalStore().successMessage('Convite enviado com sucesso');
                return true;
            } catch (error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryUpdateManager(id, params, page){
            try{

                let formData = useGlobalStore().getOnlyParams(params, this.manager);

                formData['_method'] = 'put';

                await Api.post('/stores/' + this.store.id + '/managers/' + id, formData);
                await this.tryReferrals();

                useGlobalStore().successMessage('Gerente atualizado com sucesso');
                useGlobalStore().loader('hide')
                return true;
            } catch (error){
                useGlobalStore().loader('hide')
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryDeleteManager(store_id, id){
            try{
                await Api.delete('stores/' + store_id + '/managers/' + id);
                await this.tryReferrals();
                useGlobalStore().successMessage('Gerente removido com sucesso');
            } catch (error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryDeleteManagerInvite(store_id, id){
            try{
                await Api.delete('stores/' + store_id + '/managers/invitations/' + id);
                useGlobalStore().successMessage('Convite removido com sucesso');
            } catch (error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryManagerAccept(code){
            try{
                await Api.get('invitation/manager/' + code);
                useGlobalStore().successMessage('Convite aceito com sucesso');
                return true;
            } catch (error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        //AFILIAÇÃO
        //AFILIAÇÃO

        async tryReferrals(){
            try{
                const response = await Api.get('/stores/' + this.store.id + '/referrals')
                this.referralslist = response.data.data.referrals;
                this.referralsinvites = response.data.data.invitations
                return true;
            }catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryReferral(id){
            try{
                const response = await Api.get('/stores/' + this.store.id + '/referrals/' + id)
                this.referral = response.data.data;
                this.referralmanagers = response.data.managers;
            }catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryCreateReferral(params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.referral);

                await Api.post('/stores/' + this.store.id + '/referrals/', formData);
                return true;
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryUpdateReferral(id, params, page){
            try{

                let formData = useGlobalStore().getOnlyParams(params, this.referral);

                formData['_method'] = 'put';

                await Api.post('/stores/' + this.store.id + '/referrals/' + id, formData);
                return true;
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryDeleteReferral(store_id, id){
            try{
                await Api.delete('stores/' + store_id + '/referrals/' + id);
                return true;
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },
        
        async tryDeleteReferralInvite(store_id, id){
            try{
                await Api.delete('stores/' + store_id + '/referrals/invitations/' + id);
                return true;
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryReferralAccept(code){
            try{
                await Api.get('/invitation/referral/' + code);
                return true;
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        //FRETE - TRANSPORTADORAS
        //FRETE - TRANSPORTADORAS
        
        async tryShippingCompanies(){
            try{
                const response = await Api.get('/stores/' + this.store.id + '/shipping-companies/')
                this.shipping_companies = response.data.data;
            }catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },
        
        async tryShippingCompany(id){
            try{
                let shipping_company = useGlobalStore().getObjectById(this.shipping_companies, id);
    
                if(shipping_company){
                    this.shipping_company = shipping_company;
                }else {
                    const response = await Api.get('/stores/' + this.store.id + '/shipping-companies/' + id)
                    this.shipping_company = response.data.data;
                }

                return true;
            }
            catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryCreateShippingCompany(params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.shipping_company);
                await Api.post('/stores/' + this.store.id + '/shipping-companies/', formData);

                useGlobalStore().successMessage('Transportadora criado com sucesso');

                return true;
            } catch (error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryUpdateShippingCompany(params, page){

            try{
                let formData = useGlobalStore().getOnlyParams(params, this.shipping_company);

                formData['_method'] = 'put';

                await Api.post('/stores/' + this.store.id + '/shipping-companies/' + this.shipping_company.id, formData);
                useGlobalStore().successMessage('Transportadora atualizada com sucesso');

                return true;
            }
             catch (error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryDeleteShippingCompany(id){
            try{
                await Api.delete('stores/' + this.store.id + '/shipping-companies/' + id);

                useGlobalStore().successMessage('Transportadora removida com sucesso');

                return true;
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },


        //FRETE - MÉTODOS DE ENVIO
        //FRETE - MÉTODOS DE ENVIO

        async tryShippingMethods(){
            try{
                const response = await Api.get('/stores/' + this.store.id + '/shipping-methods/')
                this.shipping_methods = response.data.data;
            }catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryShippingMethod(id){
            try{
                let shipping_method = useGlobalStore().getObjectById(this.shipping_methods, id);

                if(shipping_method){
                    this.shipping_method = shipping_method;
                }else {
                    const response = await Api.get('/stores/' + this.store.id + '/shipping-methods/' + id)
                    this.shipping_method = response.data.data;
                }

                return true;
            }
            catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryCreateShippingMethod(params, page){
            try{
                let formData = useGlobalStore().getOnlyParams(params, this.shipping_method);
                await Api.post('/stores/' + this.store.id + '/shipping-methods/', formData);

                useGlobalStore().successMessage('Transportadora criado com sucesso');

                return true;
            } catch (error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryUpdateShippingMethod(params, page){

            try{
                let formData = useGlobalStore().getOnlyParams(params, this.shipping_method);

                formData['_method'] = 'put';

                await Api.post('/stores/' + this.store.id + '/shipping-methods/' + this.shipping_method.id, formData);
                useGlobalStore().successMessage('Método de envio atualizado com sucesso');

                return true;
            }
             catch (error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryDeleteShippingMethod(id){
            try{
                await Api.delete('stores/' + this.store.id + '/shipping-methods/' + id);

                useGlobalStore().successMessage('Método de envio removida com sucesso');

                return true;
            }
            catch (error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

    },
});