<template>
    <div class="container p-4">
       <div id="box_select_store">
        <div class="center">
            <div class="icon">
                <font-awesome-icon icon="far fa-store"></font-awesome-icon>
            </div>  
            <h6 class="mt-2 p-3">Selecione uma loja para continuar</h6>
            <div class="dropdown">
                <baseButton class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true" >
                    Selecione
                </baseButton>
                <div v-if="storeStores.stores" class="dropdown-menu" x-placement="top-start" aria-labelledby="dropdownMenu">
                    <router-link v-for="(store, index) in storeStores.stores" :key="index" @click="loadMethods(store.id)" :to="'/' + props.create_view + '/create/store='+ store.id" class="dropdown-item">{{ store.title }}</router-link>
                </div>
                <div v-else class="dropdown-menu" x-placement="top-start" aria-labelledby="dropdownMenu">
                    <router-link to="/campaigns/create" class="dropdown-item">...</router-link>
                </div>
            </div>
        </div>
       </div>
    </div>
</template>

<script setup>
import { onBeforeMount } from 'vue';

import { useStoreStore } from '@/stores/store';
const storeStores = useStoreStore();

onBeforeMount(() => {
    storeStores.tryStores();
});

const props = defineProps({
    create_view: {
        required: true,
    }
})

const emit = defineEmits(["loadMethods"]);

function loadMethods(id){
    emit('loadMethods', id)
}

</script>

<style scoped>
#box_select_store {
    background-color: #fff;
    padding: 30px;
    border: 1px #d7d7d7 solid;
    border-radius: 8px;
    max-width: 400px;
    margin: 0 auto;
}

#box_select_store .dropdown-menu {
    width: 100%;
}
.center{
    margin: 0 auto;
    text-align: center;
    color: #414951;
}

.icon{
    background-color: #e9e9e9;
    border-radius: 80px;
    padding: 20px;
    font-size: 30px;
    width: 80px;
    height: 80px;
    text-align: center;
    color: #8e8e8e;
    display: block;
    margin: 0 auto;
}

.dropup{
    margin: 0 auto;
    width: 75%;
}

.dropdown-menu a{
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
}

.btn-primary{
    width: 100%;
    height: 38px;
}
</style>