<template>
    <div class="container px-2 px-lg-4">
        <BaseHeader :title="title"></BaseHeader>
        <BaseFilter @search="search" :initialSearch="initialSearch">
            <div class="item action btn-group">
                <BaseButton class="btn-filter btn-primary w-100" data-bs-toggle="dropdown" ariahashpopup="true" aria-expanded="true" data-bs-auto-close="outside">
                    <font-awesome-icon icon="far fa-filter" />
                    Filtros
                    <span class="filters_qty_active">{{ totalFilters }}</span>
                </BaseButton>
                <div class="dropdown-menu dropdown-menu-end py-0"  aria-labelledby="dropdownMenu">
                    <FilterRefuseds @filters="filters" :initialFilters="initialFilters" @filtersQty="filtersQty"/>
                </div>
            </div>
            <div class="item action btn-group">
                <BaseButton class="btn-primary w-100 dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                Exportar
                </BaseButton>
                <div class="dropdown-menu" x-placement="bottom-start" aria-labelledby="dropdownMenu">
                    <a class="dropdown-item d-flex justify-content-center flex-column" target="_blank" :href="exportUrl + 'csv' + exportParams">CSV</a>
                    <a class="dropdown-item d-flex justify-content-center flex-column" target="_blank" :href="exportUrl + 'xls' + exportParams">XLS</a>
                </div>
            </div>
        </BaseFilter>
        <BaseTable class="no_top_radius" :columns="tableColumns" :paginate="paginate" :isLoading="isLoading" :qty="refuseds.length" @changePage="changePage">
            <tr v-for="(refused, key) in refusedStore.refuseds" :style="'border-left: 3px solid' + refused.status.color" :key="key">
                <td width="10">{{ refused.id }}</td>
                <td>
                    {{ refused.client.name }}
                    <small v-if="refused.client.email">
                        <span class="d-block light"> {{  refused.client.email }}</span>
                    </small>
                    <small>
                        <a :href="'https://api.whatsapp.com/send?phone=55' + (refused.client.phone.replace(/\D/g,''))" target="_blank"   v-if="refused.client.phone" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Abrir whatsapp">
                            {{ refused.client.phone }}
                        </a>
                        <span v-if="refused.referral" class="d-block">
                        <font-awesome-icon icon="fa-regular fa-handshake" />
                        {{ refused.referral.name}}
                    </span>
                    </small>

                </td>
                <td><div class="sensitive_information">{{ globalStore.formatMoney(refused.value) }}</div></td>
                <td>
                    <BaseBadget v-if="refused.status.refused_message" :text="refused.status.refused_message" :color="refused.status.color" />
                </td>
                <td> {{ globalStore.formatDate(refused.created_at)}}</td>
                <td><BaseStoreDot :color="refused.store.color" :title="refused.store.title" /></td>
                <td class="actions">
                    <a :href="'/orders/' + refused.id" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Alterar" @click.prevent="editRefused(refused.id)"><font-awesome-icon icon="fas fa-pen-to-square" /></a>
                    <a :href="'/recovery/orders-refused/view/' + refused.id" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Ver" @click.prevent="showRefused(refused.id)"><font-awesome-icon icon="fas fa-eye" /></a>
                </td>
            </tr>
        </BaseTable>
    </div>
    <router-view></router-view>
</template>
<script setup>
const title = {title: 'Pedidos recusados', icon: 'fa-clock-rotate-left'};
import FilterRefuseds from '@/components/includes/recovery/refusedorders/FilterRefuseds'
import qs from "qs";

import { useRoute, useRouter } from 'vue-router';
const route = useRoute();
const router = useRouter();

import { ref, onBeforeMount } from 'vue';
import { storeToRefs } from 'pinia';

import { useRefusedStore } from '@/stores/refused';
const refusedStore = useRefusedStore();
const { refuseds, paginate } = storeToRefs(refusedStore);

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();

import { useOrderStore } from '@/stores/order/';
import BaseFilter from '@/components/ui/BaseFilter.vue';
const orderStore = useOrderStore();

const tableColumns = ['ID', 'Cliente', 'Valor', 'Motivo', 'Data', 'Loja'];

const isLoading = ref(false);

let initialFilters = null;
let initialSearch = null;

onBeforeMount(() => {
    if(!globalStore.hasPermissionTo('orders_refused')) {
        return router.push('/403');
    }

    if(refusedStore.refuseds.length == undefined){
        isLoading.value = true;
    }

    if(route.query.search || route.query.page || route.query.date_start || route.query.date_end) {

        Object.keys(refusedStore.refuseds_filters).forEach((key) => {
            if(key == 'search') {
                initialSearch = route.query.search;
            }else if(key != 'search'){
                let currentThing = route.query[key];
                refusedStore.refuseds_filters[key] = '';
                refusedStore.refuseds_filters[key] = currentThing;
            }
            let param = route.query[key];
            refusedStore.refuseds_filters[key] = (param == undefined) ? null : param;
        });

        initialFilters = refusedStore.refuseds_filters;
    }

    loadRefuseds(false).then(() =>{
        isLoading.value = false;
    })

    if(refusedStore.refuseds_filters.date_start && refusedStore.refuseds_filters.date_end){
        initialFilters = {};
        initialFilters['date_range'] = refusedStore.refuseds_filters.date_start + '@' + refusedStore.refuseds_filters.date_end
    }
});

function search(r) {
    refusedStore.refuseds_filters.search = r;
    loadRefuseds();
}

const totalFilters = ref(0);

function filtersQty(r) {
    totalFilters.value = r;
}

function filters(r) {
    Object.keys(r).forEach((key) => {
        refusedStore.refuseds_filters[key] = r[key];
    });
    globalStore.loader('show');
    loadRefuseds();
}

let isChangingPage = false;
async function changePage(page) {
    if(isChangingPage) return;
    refusedStore.refuseds_filters.page = page;
    globalStore.loader('show');
    isChangingPage = true;
    loadRefuseds(false).then(() => {
        isChangingPage = false;
        window.scrollTo({ top:0, left:0, behavior: "instant"});
    });
}

// eslint-disable-next-line
const exportUrl = ref(process.env.VUE_APP_API_URL + 'recovery/refused/export/');
const exportParams = ref('');

async function loadRefuseds() {
    let usedParams = {};
    if(initialFilters){
        initialFilters.date_range = null
    }
    Object.keys(refusedStore.refuseds_filters).forEach((key) => {
        if(refusedStore.refuseds_filters[key] != null && refusedStore.refuseds_filters[key] != '') {

            let currentParams = {
                ...usedParams,
                [key]: refusedStore.refuseds_filters[key]
            };
            usedParams =  currentParams;
        }
    });
    router.replace({query: usedParams});
    exportParams.value = (usedParams) ? '?' + qs.stringify(usedParams, { arrayFormat: "brackets" }) : '';
    await refusedStore.tryRefuseds(refusedStore.refuseds_filters);
    globalStore.loader('hide');
    isLoading.value = false;
}

function editRefused(id){
    globalStore.loader('show')
    orderStore.tryOrder(id).then(() =>{
        globalStore.loader('hide');
        router.push('/orders/' + id);
    })
}

function showRefused(id) {
    if(isLoading.value) {
        return;
    }
    globalStore.loader('show');
    refusedStore.tryRefused(id).then(() => {
        globalStore.loader('hide');
        router.push('/recovery/orders-refused/view/' + id);
    });
}

</script>

<style scoped>

.btn_filter button{
    margin-top: 28px;
}

.filters_qty_active{
    background-color: #5986e2;
    padding: 0px 6px;
    font-size: 12px;
    color: white;
    border-radius: 40px;
    margin-left: 9px;
}


.btn-filter::after {
    display: none !important;
}

small {
    display: block;
    color: #215eda;
    font-weight: 500;
    font-size: 80%;
    white-space: nowrap;
}

small span {
    cursor: pointer;
}

small span:hover {
    text-decoration: underline;
}

.light{
    color: #808080;
    font-weight: 400;
    cursor:text;
}

.light:hover{
    text-decoration: none;
}

</style>