<template>
    <div class="mt-2 mb-4">
        <BaseTable :columns="tableColumns" :paginate="paginate" :isLoading="isLoading" :qty="coproductors.length" :multirows="true" @changePage="changePage" :initialSearch="initialSearch">
            <template v-slot:thead>
                <td class="thead" width="60%">Nome</td>
                <td class="thead" >Status</td>
                <td></td>
            </template>
            <template v-slot:tbody>
                <template v-for="(coproductor, key) in coproductorStore.coproductors" :key="key" >
                    <tr :class="[coproductor.status == 'Habilitado' ? 'enabled' : 'disabled']" class="coproductors_table">
                        <td>{{ coproductor.name }}</td>
                        <td>{{ coproductor.status }}</td>
                        <td class="actions">
                            <a :href="'/coproductors/' + coproductor.id" v-if="globalStore.hasPermissionTo('edit_coproductor')" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Editar" @click.prevent="editCoproductor(coproductor.id)"><font-awesome-icon icon="fas fa-edit" /></a>
                            <span v-if="globalStore.hasPermissionTo('destroy_coproductor')" @click="openPopup(coproductor.id)" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Deletar"><font-awesome-icon icon="fas fa-trash-can" /></span>
                        </td>
                    </tr>
                    <tr v-for="(product, id) in coproductor.products" :key="id" class="related" :class="[coproductor.status == 'Habilitado' ? 'enabled' : 'disabled']">
                        <div class="products_table" >
                            <td>
                                {{ product.title }} - {{ product.comission }}% - <font-awesome-icon  @click="goToProduct(product.url)" @click.middle="goToProduct(product.url)" icon="far fa-square-arrow-up-right" class="product_url"/>
                            </td>
                        </div>
                        <td class="products_table">
                        </td>
                        <td class="products_table">
                        </td>
                    </tr>
                </template>
            </template>
        </BaseTable>    
    </div>
    <router-view></router-view>
    <BasePopup v-if="confirmAction" closeType="stayOnPage" width="400" @clickToClose="clickToClose">
        <div class="confirm_action">
            <div class="popup ">
                <p class="title">Atenção</p>
                <p class="text">Deseja remover esse coprodutor?</p>
                <h6>Escreva a palavra "EXCLUIR" para confirmar.</h6>
                <BaseInput
                    label=''
                    type="text"
                    id="excludeTextConfirm"
                    v-model="excludeTextConfirm"
                    :page="route.name"
                    class="col-12"
                />
                <baseButton @click="clickToClose" style="width:110px" class="btn-secondary me-2">Cancelar</baseButton>
                <baseButton @click="deleteCoproductor" style="width:150px" class="btn-success ms-2" :disabled="isLoading ? true : excludeTextConfirm == 'EXCLUIR' || excludeTextConfirm == 'excluir'  ? false : true">Sim, remover</baseButton>
            </div>
        </div> 
    </BasePopup>
</template>

<script setup>
import { ref, onBeforeMount, watch, shallowRef } from 'vue';
import { storeToRefs } from 'pinia';
import { useGlobalStore } from '@/stores/global';
import { useCoproductorStore } from '@/stores/coproductor';
import { useRouter, useRoute } from 'vue-router';

const coproductorStore = useCoproductorStore();
const { coproductors, paginate } = storeToRefs(coproductorStore);
const tableColumns = ['Nome', 'Status'];

const globalStore = useGlobalStore();
const { defaultStore } = storeToRefs(globalStore);

const route = useRoute();
const router = useRouter();

const isLoading = ref(true);
const params = ref({
    'search': null,
    'page': null
});

const confirmAction = shallowRef(null);
const excludeTextConfirm = ref(null);

let initialSearch = null;

onBeforeMount(() => {
if(route.query.search || route.query.page) {

    Object.keys(params.value).forEach((key) => {
        if(key == 'search') {
            initialSearch = route.query.search;
        }
        let param = route.query[key];
        params.value[key] = (param == undefined) ? null : param;
    });
    }

    loadCoproductor().then(() => {
        isLoading.value = false;
    });
});

function search(r) {
    params.value.search = r;
    params.value.page = null;
    loadCoproductor();
}



let isChangingPage = false;
async function changePage(page) {
    if(isChangingPage) return;
    params.value.page = page;
    globalStore.loader('show');
    isChangingPage = true;
    loadCoproductor().then(() => {
        isChangingPage = false;
        window.scrollTo({ top:0, left:0, behavior: "instant"});
    });
}

let loadDefaultStore = false;
    watch(defaultStore, function () {
    if(!loadDefaultStore) {
        loadDefaultStore = true;
        params.value.page = null;
        globalStore.loader('show');
        loadCoproductor().then(()=> {
            globalStore.loader('hide');
            loadDefaultStore = false;
        });
    }
});

async function loadCoproductor() {
    let usedParams = {};
    Object.keys(params.value).forEach((key) => {
        if(params.value[key] != null && params.value[key] != '') {

            let currentParams = {
                ...usedParams,
                [key]: params.value[key]
            };
            usedParams =  currentParams;
        }
    });

    router.replace({query: usedParams});
    await coproductorStore.tryCoproductors(params.value);
    globalStore.loader('hide');
}

function editCoproductor(id){
    globalStore.loader('show')
    coproductorStore.tryCoproductor(id).then(() =>{
        router.push('/coproductors/' + id)
    })
}

let coproductorToDelete = null;
let isDeleting = false;
async function deleteCoproductor(){
    if(!isDeleting && !isLoading.value && coproductorToDelete && excludeTextConfirm.value == 'EXCLUIR' || excludeTextConfirm.value == 'excluir') {
        isLoading.value = true;
        await coproductorStore.tryDeleteCoproductor(coproductorToDelete).then(function(success) {
            globalStore.loader('hide');
            isDeleting = false;
            confirmAction.value = null;
            excludeTextConfirm.value = null;
            if(success){
                coproductorStore.tryCoproductors();
                isLoading.value = false;
            } else {
                isLoading.value = false;
            }
        }); 
    }
}

function openPopup(coproductor) {
    coproductorToDelete = coproductor
    confirmAction.value = true
    excludeTextConfirm.value = null;
}

function clickToClose() {
    confirmAction.value = null;
}

function goToProduct(url){
    window.open(url)
}

defineExpose({
    search
})

</script>

<style scoped>

.thead{
    font-weight: bold;
}

.products_table{
    border: none;
}

.coproductors_table{
    border-top: 1px solid #ddd ;
}

tbody .enabled {
    border-left: 3px #28a745 solid;
}

tbody .disabled {
    border-left: 3px #c51515 solid;
}

.product_url{
    cursor: pointer;
    color: #0056b3;
}

.product_url:hover{
    color: #215eda;
}

</style>