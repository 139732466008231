import TheProducts from '@/views/products/TheProducts';
import ProductsCreate from '@/views/products/create/ProductsCreate';
import ProductorProducts from '@/views/products/productor/ProductProductor';
import CoproductorProducts from '@/views/products/coproductor/ProductCoproductor';
import ReferralProducts from '@/views/products/referral/ProductReferral';

import ProductEdit from '@/views/products/edit/ProductEdit';
import ProductEditInfo from '@/views/products/edit/informations/ProductEditInfo';

import ProductCourse from '@/views/products/edit/course/ProductCourse';
import ProductCourseModuleDialog from '@/views/products/edit/course/ProductCourseModuleDialog';
import ProductCourseLessonDialog from '@/views/products/edit/course/ProductCourseLessonDialog';
import ProductCourseComments from '@/views/products/edit/course/ProductCourseComments';
import ProductCourseCommentDialog from '@/views/products/edit/course/ProductCourseCommentDialog';

import ProductFiles from '@/views/products/edit/files/ProductFiles';
import ProductFileDialog from '@/views/products/edit/files/ProductFileDialog';

import ProductLotes from '@/views/products/edit/lotes/ProductLotes';
import ProductLoteDialog from '@/views/products/edit/lotes/ProductLoteDialog';

import ProductUpsells from '@/views/products/edit/upsells/ProductUpsells';
import ProductUpsellDialog from '@/views/products/edit/upsells/ProductUpsellDialog';
import ProductUpsellCustomization from '@/views/products/edit/upsells/ProductUpsellCustomization';

import ShippingMethods from '@/views/products/edit/shipping/ShippingMethods';

import ProductEditCustomFields from '@/views/products/edit/customfields/ProductEditCustomField';
import CustomFieldsDialog from '@/views/products/edit/customfields/ProductCustomFieldsDialog';

import ProductEditCheckoutPersonalization from '@/views/products/edit/checkout-personalization/ProductEditCheckout';

import ProductOrderBumps from '@/views/products/edit/bumps/ProductOrderBumps';
import ProductOrderBumpDialog from '@/views/products/edit/bumps/ProductOrderBumpDialog';

import ProductSmartBuy from '@/views/products/edit/smart-buy/ProductEditSmartBuy';

import ProductEditInstallmentInterest from '@/views/products/edit/installmentinterest/ProductEditInstallmentInterest';

import ProductCoproductors from '@/views/products/edit/coproductor/ProductCoproductors';
import ProductCoproductorDialog from '@/views/products/edit/coproductor/ProductCoproductorDialog';

import ProductEvents from '@/views/products/edit/events/ProductEvents';

import ProductEventsMautic from '@/views/products/edit/events/mautic/ProductEventDataMautic';
import ProductEventsMauticDialog from '@/views/products/edit/events/mautic/productEventMauticDialog'

import ProductEventsLeadlovers from '@/views//products/edit/events/leadlovers/ProductEventDataLeadlovers';
import ProductEventsLeadloversDialog from '@/views/products/edit/events/leadlovers/productEventLeadloversDialog'

import ProductEventsMex from '@/views/products/edit/events/mex/ProductEventDataMex';
import ProductEventsMexDialog from '@/views/products/edit/events/mex/productEventMexDialog'


export default [
    {
        name: 'products',
        path: '/products',
        component: TheProducts,
        meta: {
            requiresAuth: true,
            menuPage: 'products',
        },
        children: [
            {
                name: 'productsProductor',
                path: '/products',
                component: ProductorProducts,
            },

            {
                name: 'productsCoproductor',
                path: '/products/coproductor',
                component: CoproductorProducts,
            },

            {
                name: 'productsReferral',
                path: '/products/referral',
                component: ReferralProducts,
            },
        ]
    },
    {
        name: 'productCreateDefinestore',
        path: '/products/create',
        component: ProductsCreate,
        meta: {
            requiresAuth: true,
            menuPage: 'products',
        },
    },
    {
        name: 'productCreate',
        path: '/products/create/store=:id',
        component: ProductsCreate,
        meta: {
            requiresAuth: true,
            menuPage: 'products',
        }
    },
    { 
        name: 'product',
        path: '/products/:id/',
        component: ProductEdit,
        meta: {
            requiresAuth: true,
            menuPage: 'products',
        },
        children: [
            {
                name: 'productInfo',
                path: '/products/:id/',
                component: ProductEditInfo,
            },

            {
                name: 'productCourse',
                path: '/products/:id/course',
                component: ProductCourse,
                children: [
                    {
                        name: 'productCourseModulesCreate',
                        path: '/products/:id/course/modules/new',
                        component: ProductCourseModuleDialog,
                    },
                    {
                        name: 'productCourseModulesEdit',
                        path: '/products/:id/course/modules/:slug',
                        component: ProductCourseModuleDialog,
                    },

                    {
                        name: 'productCourseLessonsCreate',
                        path: '/products/:id/course/lessons/:slug/new',
                        component: ProductCourseLessonDialog,
                    },
                    {
                        name: 'productCourseLessonsEdit',
                        path: '/products/:id/course/lessons/:slug',
                        component: ProductCourseLessonDialog,
                    },
                ]
            },

            {
                name: 'productCourseComments',
                path: '/products/:id/course/comments',
                component: ProductCourseComments,
                children: [
                    {
                        name: 'productCourseCommentDialog',
                        path: '/products/:id/course/comments/:slug',
                        component: ProductCourseCommentDialog
                    }

                ]
            },

            {
                name: 'productFiles',
                path: '/products/:id/files',
                component: ProductFiles,
                children: [
                    {
                        name: 'productFileCreate',
                        path: '/products/:id/files/new',
                        component: ProductFileDialog
                    },
                    {
                        name: 'productFileEdit',
                        path: '/products/:id/files/:slug',
                        component: ProductFileDialog
                    }

                ]
            },

            {
                name: 'productUpsells',
                path: '/products/:id/upsells',
                component: ProductUpsells,
                children: [
                    {
                        name: 'productUpsellCreate',
                        path: '/products/:id/upsells/new',
                        component: ProductUpsellDialog
                    },
                    {
                        name: 'productUpsellEdit',
                        path: '/products/:id/upsells/:slug',
                        component: ProductUpsellDialog
                    }

                ]
            },

            {
                name: 'productLotes',
                path: '/products/:id/lotes',
                component: ProductLotes,
                children: [
                    {
                        name: 'productLoteCreate',
                        path: '/products/:id/lotes/new',
                        component: ProductLoteDialog
                    },
                    {
                        name: 'productLoteEdit',
                        path: '/products/:id/lotes/:slug',
                        component: ProductLoteDialog
                    }
                ]
            },

            {
                name: 'productShippingMethods',
                path: '/products/:id/shipping-methods',
                component: ShippingMethods,
            },

            {
                name: 'productCustomFields',
                path: '/products/:id/custom-fields',
                component: ProductEditCustomFields,
                children: [
                    {
                        name: 'productCustomFieldsDialog',
                        path: '/products/:id/custom-fields/:slug',
                        component: CustomFieldsDialog,
                    }
                ]
            },

            {
                name: 'productCheckoutPersonalization',
                path: '/products/:id/checkout-personalization',
                component: ProductEditCheckoutPersonalization,
            },

            {
                name: 'productOrderBumps',
                path: '/products/:id/bump',
                component: ProductOrderBumps,
                children: [
                    {
                        name: 'productOrderBumpCreate',
                        path: '/products/:id/bump/new',
                        component: ProductOrderBumpDialog,
                    },
                    {
                        name: 'productOrderBumpEdit',
                        path: '/products/:id/bump/:slug',
                        component: ProductOrderBumpDialog,
                    }

                ]
            },
            {
                name: 'productSmartBuy',
                path: '/products/:id/smart-buy',
                component: ProductSmartBuy,
            },
            {
                name: 'productInstallmentInterest',
                path: '/products/:id/installment-interest',
                component: ProductEditInstallmentInterest,
            },

            {
                name: 'productCoproductors',
                path: '/products/:id/coproductor',
                component: ProductCoproductors,
                children: [
                    {
                        name: 'productCoproductorCreate',
                        path: '/products/:id/coproductor/new',
                        component: ProductCoproductorDialog
                    },
                    {
                        name: 'productCoproductorEdit',
                        path: '/products/:id/coproductor/:slug',
                        component: ProductCoproductorDialog
                    }
                ]
            },

            {
                name: 'events',
                path: '/products/:id/events',
                component: ProductEvents,
                children: [
                    {
                        name: 'eventsMautic',
                        path: '/products/:id/events/mautic',
                        component: ProductEventsMautic,
                        children: [
                             {
                                name: 'mauticDialog',
                                path: '/products/:id/events/mautic/:slug',
                                component: ProductEventsMauticDialog,
                             }
                        ]
                    },

                    {
                        name: 'eventsLeadLovers',
                        path: '/products/:id/events/leadlovers',
                        component: ProductEventsLeadlovers,
                        children: [
                            {
                               name: 'leadLoversDialog',
                               path: '/products/:id/events/leadlovers/:slug',
                               component: ProductEventsLeadloversDialog,
                            }
                       ]
                    },

                    {
                        name: 'eventsMex',
                        path: '/products/:id/events/mex',
                        component: ProductEventsMex,
                        children: [
                            {
                               name: 'mexDialog',
                               path: '/products/:id/events/mex/:slug',
                               component: ProductEventsMexDialog,
                            }
                       ]
                    },
                ]
            },

        ]
    },
    {
        name: 'productUpsellCustomization',
        path: '/products/:id/upsells/:slug/customization',
        component: ProductUpsellCustomization,
        meta: {
            requiresAuth: true,
            menuPage: 'products'
        }
    },
];