<template>
    <div class="container px-2 px-lg-4">
        <BaseHeader :title="title">
        </BaseHeader>

        <div class="row">
            <div class="col-md">
                <div class="box_content mb-3 mt-md-0 py-3 px-3 pe-4">
                    <BaseInfoGroup
                        svg_icon="fa-solid fa-cart-shopping"
                        title="Total de pedidos"
                        :info_value="orderStore.report.qty_total"
                    />
                </div>
            </div>
            <div class="col-md">
                <div class="box_content mb-3 mt-md-0 py-3 px-3 pe-4">
                    <BaseInfoGroup
                        svg_icon="fa-regular fa-calculator-simple"
                        title="Valor total"
                        :info_value="orderStore.report.amount_total === undefined ? 'R$ 0,00' : globalStore.formatMoney(orderStore.report.amount_total)"
                    />
                </div>
            </div>
            <div class="col-md">
                <div class="box_content mb-4 mt-md-0 py-3 px-3 pe-4">
                    <BaseInfoGroup
                        svg_icon="fa-solid fa-handshake"
                        title="Comissão"
                        :info_value="orderStore.report.amount_total_comissions === undefined ? 'R$ 0,00' : globalStore.formatMoney(orderStore.report.amount_total_comissions)"
                    />
                </div>
            </div>
        </div>
        <BaseFilter :title="title" @search="search" :initialSearch="initialSearch">
            <div class="item action">
                <BaseButton class="btn-filter btn-primary w-100" data-bs-toggle="dropdown" ariahashpopup="true" aria-expanded="true" data-bs-auto-close="outside">
                    <font-awesome-icon icon="far fa-filter" />
                    Filtros
                    <span class="filters_qty_active">{{ totalFilters }}</span>
                </BaseButton>
                <div class="dropdown-menu dropdown-menu-end py-0"  aria-labelledby="dropdownMenu">
                    <FilterOrders @filters="filters" :initialFilters="initialFilters" @filtersQty="filtersQty" />
                </div>
            </div>
            <div class="item action btn-group">
                <BaseButton class="btn-primary w-100 dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                Exportar
                </BaseButton>
                <div class="dropdown-menu" x-placement="bottom-start" aria-labelledby="dropdownMenu">
                    <a class="dropdown-item d-flex justify-content-center flex-column" target="_blank" :href="exportUrl + 'csv' + exportParams">CSV</a>
                    <a class="dropdown-item d-flex justify-content-center flex-column" target="_blank" :href="exportUrl + 'xls' + exportParams">XLS</a>
                </div>
            </div>
        </BaseFilter>
        <BaseTable class="no_top_radius" :columns="tableColumns" :paginate="paginate" :isLoading="isLoading" :qty="orderStore.orders.length" @changePage="changePage">
            <tr v-for="(order, key) in orderStore.orders" :style="{ borderLeftColor: order.status.color }" :key="key" :class="[order.showing_attempts || order.isAttempt  ? 'attempt' : '']" @click="(order.orders_attempts) ? loadAttempts(order.id) : null">
                <td>
                    {{ order.id }}
                    <div v-if="order.attempts">
                        <span @click="!(order.orders_attempts) ? loadAttempts(order.id) : null" class="more_orders">+{{ order.attempts }}</span>
                    </div>
                </td>
                <td>
                    {{ order.client.name }}
                    <BaseBadget small class="ms-1" v-if="order.status.name == 'Pago' && order.client.orders_qty > 1" color="#c99217" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip :title="order.client.orders_qty + 'ª Compra'" :text="order.client.orders_qty+'ª'" />
                    <BaseBadget small class="ms-1" v-if="order.type != 'checkout'" icon="fa-arrow-up" iconType="fas" :color="order.status.color" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip :title="globalStore.capitalizeText(order.type)" />
                    <BaseBadget small class="ms-1" v-if="order.smart_buy === true" icon="fa-bolt" iconType="fas" :color="order.status.color" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Compra inteligente" />
                    
                    <small v-if="order.referal" :id="order.referal.id">
                        <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Afiliado">
                            <font-awesome-icon icon="far fa-handshake" />
                            {{ order.referal.name }}
                        </span>
                    </small>
                </td>
                <td><div class="sensitive_information">{{ globalStore.formatMoney(order.comissions.your_comission) }}</div></td>
                <td><div class="sensitive_information">{{ globalStore.formatMoney(order.table_values.total) }}</div></td>
                <td>
                    <BaseBadget v-if="order.payment.refused_mesage_internal && order.status.name == 'Recusado'" :text="order.status.name" :color="order.status.color" :icon="order.status.icon" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip :data-bs-original-title="order.payment.refused_mesage_internal" :title="order.payment.refused_mesage_internal" />

                    <BaseBadget v-else :text="order.status.name" :color="order.status.color" :icon="order.status.icon" />

                    <span v-if="order.payment.notifications" class="ms-1 alert_triangle" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip :title="order.payment.notifications"><font-awesome-icon icon="fas fa-exclamation-triangle" /></span>

                    <BaseBadget v-if="order.payment.recurrent" class="ms-1" text="" :color="order.status.color" icon="fa-rotate-left" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Recorrência" />
                </td>
                <td>{{ globalStore.formatDate(order.payment.date) }}</td>
                <td>
                    <BaseStoreDot :color="order.store.color" :title="order.store.title" />
                </td>
                <td class="actions">
                    <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip :title="[order.finished_mark == true ? 'Remover marcação' : 'Marcar pedido']" @click="changeFinished(order.id, order.finished_mark, key)" :data-bs-original-title="[order.finished_mark == true ? 'Remover marcação' : 'Marcar pedido']" :class="'mark_order_finished ' + [order.finished_mark == true ? 'marked' : '']"><font-awesome-icon icon="fas fa-star" /></span>
                    <router-link v-if="globalStore.hasPermissionTo('edit_order') && order.ordered_as_productor == true" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Detalhes" :to="'orders/' + order.id"><span><font-awesome-icon icon="fas fa-pen-to-square" /></span></router-link>
                    <router-link v-if="globalStore.hasPermissionTo('show_order')" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Ver" :to="'orders/' + order.id + '/show'"><span><font-awesome-icon icon="fas fa-eye" /></span></router-link>
                </td>
            </tr>
        </BaseTable>
    </div>
    <router-view></router-view>
</template>
<script setup>
const title = {title: 'Pedidos', icon: 'fa-shopping-cart'};
import FilterOrders from '@/components/includes/orders/FilterOrders';
import BaseFilter from '@/components/ui/BaseFilter';

import { useGlobalStore } from '@/stores/global';
import { useOrderStore } from '@/stores/order';
import { useProductStore } from '@/stores/products';
import { useAccountStore } from '@/stores/account';
import { useRouter, useRoute } from 'vue-router'; 
import { storeToRefs } from 'pinia';
import qs from "qs";

const globalStore = useGlobalStore();
const { defaultStore } = storeToRefs(globalStore);

const orderStore = useOrderStore();
const { paginate } = storeToRefs(orderStore);

const productStore = useProductStore();

const accountStore = useAccountStore()

const tableColumns = ['ID', 'Cliente', 'Comissão', 'Total', 'Status', 'Data', 'Loja'];

import { ref, onBeforeMount, watch } from 'vue';

const route = useRoute();
const router = useRouter();

let initialSearch = null;
let initialFilters = null;
const isLoading = ref(false);

onBeforeMount(() => {
    if(!globalStore.hasPermissionTo('orders')) {
        return router.push('/403');
    }

    if(orderStore.orders.length == undefined){
        isLoading.value = true;
    }

    if(globalStore.defaultStore.id === undefined || globalStore.defaultStore.id === 0){
        productStore.tryProductsList('');
    } else {
        productStore.tryProductsList(globalStore.defaultStore.id);
    }
    
    if(!route.query.status && accountStore.user_preferences.orders_filter_status){
        orderStore.filters_orders.status = [];
        for(let key in accountStore.user_preferences.orders_filter_status){
            orderStore.filters_orders.status.push(accountStore.user_preferences.orders_filter_status[key])
        }
        initialFilters = orderStore.filters_orders
    }

    if(route.query.search ||  route.query.payment_date_start || route.query.payment_date_end ||route.query.date_start || route.date_end || route.query.status || route.query.payment_methods || route.query.page || route.query.store || route.query.product || (route.query.order_by && route.query.order_by != 'created_at') || route.query.utms_src || route.query.coupon) {
        Object.keys(orderStore.filters_orders).forEach((key) => {
            if(key == 'order_by' && route.query.order_by == undefined) {
                orderStore.filters_orders.order_by = 'created_at';

            }else{
                let param = route.query[key];
                orderStore.filters_orders[key] = (param == undefined) ? null : param;

                if(key == 'search') {
                    initialSearch = route.query[key];

                }else if(key == 'status' || key == 'payment_methods' || key == 'store' || key == 'product' || key === 'utms_src' || key === 'coupon') {
                    if((orderStore.filters_orders[key]) && orderStore.filters_orders[key].constructor !== Array) {
                        let currentThing = orderStore.filters_orders[key];
                        orderStore.filters_orders[key] = [];
                        orderStore.filters_orders[key].push(currentThing);
                    }
                }
            }
        });

        initialFilters = orderStore.filters_orders;
    }

    
    if(orderStore.filters_orders){
        Object.keys(orderStore.filters_orders).forEach((key) => {
            if(key == 'order_by' && route.query.order_by == undefined) {
                orderStore.filters_orders.order_by = 'created_at';

            }else{
                let param = orderStore.filters_orders[key];
                orderStore.filters_orders[key] = (param == undefined) ? null : param;

                if(key == 'search') {
                    initialSearch = orderStore.filters_orders[key];

                }else if(key == 'status' || key == 'payment_methods' || key == 'store' || key == 'product' || key === 'utms_src' || key === 'coupon') {
                    if((orderStore.filters_orders[key]) && orderStore.filters_orders[key].constructor !== Array) {
                        let currentThing = orderStore.filters_orders[key];
                        orderStore.filters_orders[key] = [];
                        orderStore.filters_orders[key].push(currentThing);
                    }
                }
            }
        });

        initialFilters = orderStore.filters_orders;
    }

    loadOrders(false).then(() =>{
        isLoading.value = false;
    })

    if(orderStore.filters_orders.date_start && orderStore.filters_orders.date_end){
        initialFilters['date_range'] = orderStore.filters_orders.date_start + '@' + orderStore.filters_orders.date_end
    }
    if(orderStore.filters_orders.payment_date_start && orderStore.filters_orders.payment_date_end){
        initialFilters['payment_date'] = orderStore.filters_orders.payment_date_start + '@' + orderStore.filters_orders.payment_date_end
    }

});

let isChangingPage = false;
async function changePage(page) {
    if(isChangingPage) return;
    orderStore.filters_orders.page = page;
    globalStore.loader('show');
    isChangingPage = true;
    loadOrders(false).then(() => {
        isChangingPage = false;
        window.scrollTo({ top:0, left:0, behavior: "instant"});
    });
}

// eslint-disable-next-line
const exportUrl = ref(process.env.VUE_APP_API_URL + 'orders/export/');
const exportParams = ref('');

async function loadOrders(clearPaginate = true) {
    let usedParams = {};
    if(initialFilters){
        initialFilters.date_range = null
        initialFilters.payment_date = null
    }
    Object.keys(orderStore.filters_orders).forEach((key) => {
        if(orderStore.filters_orders[key] != null && orderStore.filters_orders[key] != '' && orderStore.filters_orders[key] != 'created_at') {

            let currentParams = {
                ...usedParams,
                [key]: orderStore.filters_orders[key]
            };
            usedParams = currentParams;
        }
    });

    if(clearPaginate || orderStore.filters_orders.page == 1) {
        delete usedParams['page'];
        orderStore.filters_orders.page = null;
    }

    router.replace({query: usedParams});
    exportParams.value = (usedParams) ? '?' + qs.stringify(usedParams, { arrayFormat: "brackets" }) : '';
    await orderStore.tryOrders(orderStore.filters_orders)
    globalStore.loader('hide');
    isLoading.value = false;
}

function search(r) {
    orderStore.filters_orders.search = r;
    loadOrders();
}

function filters(r) {
    Object.keys(r).forEach((key) => {
        orderStore.filters_orders[key] = r[key];
    });
    globalStore.loader('show');
    loadOrders();
}

const totalFilters = ref(0);

function filtersQty(r) {
    totalFilters.value = r;
}

let loadDefaultStore = false;
watch(defaultStore, function () {
    if(!loadDefaultStore) {
        loadDefaultStore = true;
        globalStore.loader('show');
        loadOrders().then(()=> {
            productStore.tryProductsList(globalStore.defaultStore.id);
            globalStore.loader('hide');
            loadDefaultStore = false;
        });
    }
});

function loadAttempts(id) {
    globalStore.loader('show');
    orderStore.tryloadAttempts(id).then(() => {
        globalStore.loader('hide');
    });
}

async function changeFinished(id, status, key) {
    globalStore.loader('show');
    await orderStore.trychangeFinishedStatus(id, (status) ? 0 : 1, key);
    globalStore.loader('hide');
}

</script>

<style scoped> 

.btn-filter span{
    margin-left: 2px;
}

.filters_qty_active{
    background-color: #5986e2;
    padding: 0px 6px;
    font-size: 12px;
    color: white;
    border-radius: 40px;
    margin-left: 9px;
}

.btn-filter svg {
    margin-right: 7px;
}

.btn-filter::after {
    display: none !important;
}

tbody tr {
    border-left: 3px solid #fff;
}
small {
    display: block;
    color: #215eda;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 80%;
}

small span {
    cursor:default;
}

small span:hover {
    text-decoration: underline;
}

.alert_triangle {
    color: #d8281b;
    fill: #d8281b;
}

.more_orders {
    font-size: 10px;
    background: #215eda;
    color: #fff;
    padding: 1px 5px;
    border-radius: 50px;
    text-align: center;
    cursor: pointer;
    user-select: none;
}

.attempt, .attempt:hover {
    --bs-table-bg: #e8f3fd !important;
}

@media screen and (max-height: 700px){
    .filter_container{
        overflow: scroll!important;
        max-height: 450px!important;
    }
}

@media screen and (max-height: 600px){
    .filter_container{
        overflow: scroll!important;
        max-height: 300px!important;
    }
}


@media screen and (max-height: 450px){
    .filter_container{
        overflow: scroll!important;
        max-height: 225px!important;
    }
}

</style>