<template>
    <div class="container px-2 px-lg-4">
        <BaseHeader :title="title" removeSearchBar :redirectBack="previousPage">
        <div class="item action btn-group"  v-if="route.name == 'productInfo' || route.name == 'productShippingMethods' || route.name == 'productCustomFields' || route.name == 'productCustomFieldsDialog' || route.name == 'productCheckoutPersonalization' || route.name == 'productInstallmentInterest' || route.name == 'productSmartBuy'">
            <div class="item action btn-group">
                <baseButton @click="submit()" class="btn btn-primary w-100" :disabled="isLoading">
                    Salvar
                </baseButton>
            </div>
        </div>
        </BaseHeader>
        <div class="page_content row" style="margin-top: 0px">
            <div v-if="isLoaded != false" class="nav_div col-xl-3 col-12 mx-0 mb-lx-0 mb-4">
                <div class="navigation_bar">
                    <ul class="nav nav-pills"  role="tablist" aria-orientation="vertical">
                        <li>
                            <router-link :to="'/products/'+ product_id"  :class="[route.path === '/products/'+ product_id ? 'active': '']">Informações</router-link>
                        </li>
                        <li v-if="product.category_slug == 'course'">
                            <a class="tab_always_open_main">Curso</a>
                            <ul>
                                <li>
                                    <router-link v-if="product.category_slug == 'course'" :to="'/products/'+ product_id + '/course'"  :class="'tab_always_open ' + [route.path === '/products/'+ product_id + '/course' ? 'active': '']">Conteúdo</router-link>
                                </li>
                                <li>
                                    <router-link v-if="product.category_slug == 'course'" :to="'/products/'+ product_id + '/course/comments'"  :class="'tab_always_open ' + [route.path === '/products/'+ product_id + '/course/comments' ? 'active': '']">Comentários <span class="badge">{{  productStore.course_comments.length }}</span></router-link>
                                </li>
                            </ul>
                        </li>
                        <li v-if="product.category_slug == 'ticket'">
                            <router-link :to="'/products/'+ product_id +'/lotes'"  :class="[route.path === '/products/'+ product_id +'/lotes' ? 'active': '']">Lotes</router-link>
                        </li>
                        <li>
                            <router-link :to="'/products/'+ product_id +'/files'"  :class="[route.path === '/products/'+ product_id +'/files' ? 'active': '']">Arquivos</router-link>
                        </li>
                        <li>
                            <router-link v-if="product.type != 'digital'" :to="'/products/'+ product_id +'/shipping-methods'"  :class="[route.path === '/products/'+ product_id +'/shipping-methods' ? 'active': '']">Métodos de envio</router-link>
                        </li>
                        <li>
                            <router-link :to="'/products/'+ product_id +'/custom-fields'"  :class="[route.path === '/products/'+ product_id +'/custom-fields' ? 'active': '']">Campos personalizados</router-link>
                        </li>
                        <li>
                            <router-link :to="'/products/'+ product_id +'/checkout-personalization'"  :class="[route.path === '/products/'+ product_id +'/checkout-personalization' ? 'active': '']">Personalização do checkout</router-link>
                        </li>
                        <li>
                            <router-link :to="'/products/'+ product_id +'/bump'"  :class="[route.path === '/products/'+ product_id +'/bump' ? 'active': '']">Order bump</router-link>
                        </li>
                        <li>
                            <router-link :to="'/products/'+ product_id +'/upsells'"  :class="[route.path === '/products/'+ product_id +'/upsells' ? 'active': '']">Cross-sell</router-link>
                        </li>
                        <li>
                            <router-link :to="'/products/'+ product_id +'/smart-buy'"  :class="[route.path === '/products/'+ product_id +'/smart-buy' ? 'active': '']">Compra inteligente<span class="badge ms-2">Novo</span></router-link>
                        </li>
                        <li>
                            <router-link :to="'/products/'+ product_id +'/installment-interest'"  :class="[route.path === '/products/'+ product_id +'/installment-interest' ? 'active': '']">Juros de parcelamento</router-link>
                        </li>
                        <li>
                            <router-link :to="'/products/'+ product_id +'/coproductor'"  :class="[route.path === '/products/'+ product_id +'/coproductor' ? 'active': '']">Coprodutor</router-link>
                        </li>
                        <li>
                            <router-link :to="'/products/'+ product_id + '/events'"  :class="[route.path === '/products/'+ product_id + '/events' ? 'active': '']">Eventos</router-link>
                        </li>  
                    </ul>
                </div>
              
            </div>
            <div class="forms_div col-xl-9 col-12 mb-4">
                <div>
                  <router-view v-if="isLoaded != false"></router-view>                
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onBeforeMount, computed } from 'vue';
import { useRoute, useRouter } from "vue-router"; 
import { useGlobalStore } from '@/stores/global';
import { storeToRefs } from 'pinia';
import { useProductStore } from '@/stores/products';
const title = {title: 'Editar produto', icon: 'fa-box'};

const route = useRoute();
const router = useRouter();

const product_id = computed (function () {
    return route.params.id;
});

const productStore = useProductStore();
const { product } = storeToRefs(productStore);
const globalStore = useGlobalStore();
const previousPage = ref(null);
const isLoaded = ref(false)
const params = ref({
    price: null,
})

onBeforeMount(async () => {
    if(!globalStore.hasPermissionTo('edit_product')) {
        return router.push('/403');
    }
    previousPage.value = router.options.history.state.back;
    delete globalStore.errors[route.name];
    
    globalStore.cleanObject(productStore.product);

    globalStore.loader('show')
    productStore.tryProductsList("")
    productStore.tryProduct(product_id.value).then(() =>{
        params.value.price = productStore.product.price;
        globalStore.loader('hide')
        isLoaded.value = true
        if(productStore.product.price_promotional){
            params.value.price = productStore.product.price_promotional;
        } else{
            params.value.price = productStore.product.price;
        }
        productStore.tryInstallments(product_id.value, params.value)
        if(productStore.product.category_slug == 'course'){
            productStore.tryCourseContent(product_id.value);
            productStore.tryCourseComments(product_id.value);
        }
    })   

    if(previousPage.value){
        if(previousPage.value.split('/').length > 3){
            previousPage.value = '/products';
        }
    }

});

const isLoading = ref(false);

function submit() {
    if(!isLoading.value){
        globalStore.loader('show');
        isLoading.value = true;
        
        const params = {
            productInfo: ['title', 'tag', 'description', 'type', 'category_slug', 'price_promotional',  'status', 'price', 'image', 'banner', 'banner_content', 'cover_desktop', 'cover_mobile', 'qty', 'qty_items', 'status', 'recurrent', 'recurrence_period', 'installments', 'external_thank_you_page', 'weight', 'width', 'height', 'length', 'allow_referral', 'meta.request_address_on_digital', 'sku', 'course_title', 'course_duration', 'product_sell', 'remove_banner', 'remove_banner_content', 'remove_cover_desktop', 'remove_cover_mobile', 'shipping_comission', 'status'],
            productCheckoutPersonalization: ['enable_timer', 'enable_summary', 'enable_exit_popup', 'coupon_exit_popup', 'enable_notify'],
            productShippingMethods: ['shipping_methods', 'product_default_shipping_method' , 'shipping_comission'],
            productCustomFields: ['show_personalized_fields_on_checkout', 'personalized_fields_title'],
            productEditSmartBuy: ['meta.replace_product'],
            productInstallmentInterest: ['assume_fees'],     
        };

        if(productStore.product.category_slug === 'ticket'){
            params.productInfo.push('meta.ticket_date');
            params.productInfo.push('meta.ticket_place');
        }
        
        productStore.tryUpdateProduct(params, route.name).then(function() {
            globalStore.loader('hide');
            isLoading.value = false;
        });
    }
}
</script>

<style scoped>

.nav_div{
    padding: 0px;
    padding-right: 18px;
}

.forms_div{
    padding-left: 2px;
}

.infos_div{
    margin-right: -3px;
}

.div_logo{
    padding-left: 8px;
    padding-right: 15px;
}
.page_content {
  margin-top: 12px;
  margin-left: 0px;
}

:deep(.box_content .row){
    padding: 19px 24px 19px 24px!important;
}

:deep(.base-select) {
  background-color: white;
}

small {
  font-size: 80%;
  font-weight: 400;
}

:deep(.form-control:focus) {
  color: #495057;
  background-color: #fff;
  border-color: #b6b6b6;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 11%);
}

.badge {
    background-color: #215eda;
    float: right;
    border-radius: 20px;
}

</style>