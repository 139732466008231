<template>
    <div class="container px-2 px-lg-4">
        <BaseHeader :title="title"></BaseHeader>
        <BaseFilter @search="search" :initialSearch="initialSearch">
            <div class="item action btn-group">
                <baseButton class="btn-filter btn-primary w-100" data-bs-toggle="dropdown" ariahashpopup="true" aria-expanded="true" data-bs-auto-close="outside">
                    <font-awesome-icon icon="far fa-filter" />
                    Filtros
                    <span class="filters_qty_active">{{ totalFilters }}</span>
                </baseButton>
                <div class="dropdown-menu dropdown-menu-end py-0"  aria-labelledby="dropdownMenu">
                    <FilterCarts @filters="filters" :initialFilters="initialFilters" @filtersQty="filtersQty"/>
                </div>
            </div>
            <div class="item action btn-group">
                <baseButton class="btn-primary w-100 dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    Exportar
                </baseButton>
                <div class="dropdown-menu" x-placement="bottom-start" aria-labelledby="dropdownMenu">
                    <a class="dropdown-item d-flex justify-content-center flex-column" target="_blank" :href="exportUrl + 'csv' + exportParams">CSV</a>
                    <a class="dropdown-item d-flex justify-content-center flex-column" target="_blank" :href="exportUrl + 'xls' + exportParams">XLS</a>
                </div>
            </div>
        </BaseFilter>
        <BaseTable class="no_top_radius" :columns="tableColumns" :paginate="paginate" :isLoading="isLoading" :qty="carts.length" @changePage="changePage">
            <tr v-for="(cart, key) in carts" :style="'border-left: 3px solid' + cart.status.color" :key="key">
                <td>{{ cart.id }}</td>
                <td>
                    {{ cart.product }}
                    <span v-if="cart.referral" class="d-block">
                        <small>
                            <font-awesome-icon icon="fa-regular fa-handshake" />
                            {{ cart.referral.name}}
                        </small>
                    </span>
                </td>
                <td> 
                    {{ cart.client.name }} 
                    <small class="light">{{ cart.client.email }}</small>
                    <small>
                        <a :href="'https://api.whatsapp.com/send?phone=55' + (cart.client.phone.replace(/\D/g,''))" target="_blank" v-if="cart.client.phone" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Abrir whatsapp">
                            {{ cart.client.phone }}
                        </a> 
                    </small>
                </td>
                <td> {{ globalStore.formatDate(cart.created_at)}}</td>
                <td>
                    <BaseBadget :text="cart.status.name" :color="cart.status.color" />
                </td>
                <td><BaseStoreDot :color="cart.store.color" :title="cart.store.title"/></td>
                <td class="actions">
                    <a :href="cart.url" target="_blank" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Acessar"><font-awesome-icon icon="fas fa-external-link-square-alt" /></a>
                    <a :href="'/recovery/abandoned-carts/' + cart.id" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Detalhes" @click.prevent="watchCart(cart.id)"><font-awesome-icon icon="fas fa-eye" /></a>
                </td>
            </tr>
        </BaseTable>
    </div>
</template>
<script setup>
const title = {title: 'Checkouts abandonados', icon: 'fa-clock-rotate-left'};
import FilterCarts from "@/components/includes/recovery/abandonedcarts/FilterCarts.vue"
import qs from "qs";

import { useRoute, useRouter } from 'vue-router';
const route = useRoute();
const router = useRouter();

import { ref, onBeforeMount } from 'vue';
import { storeToRefs } from 'pinia';

import { useCartStore } from '@/stores/cart';
const cartStore = useCartStore();
const { carts, paginate } = storeToRefs(cartStore);

import { useGlobalStore } from '@/stores/global';
import BaseFilter from "@/components/ui/BaseFilter.vue";
const globalStore = useGlobalStore();

const tableColumns = ['ID', 'Produto' ,'Cliente', 'Data', 'Status', 'Loja'];

const isLoading = ref(false);

let initialFilters = null;
let initialSearch = null;

onBeforeMount(() => {
    if(!globalStore.hasPermissionTo('abandoned_carts')) {
        return router.push('/403');
    }


    if(cartStore.carts.length == undefined){
        isLoading.value = true;
    }

    if(route.query.search || route.query.page ||  route.query.date_start || route.query.date_end || route.query.status) {

        Object.keys(cartStore.carts_filters).forEach((key) => {
            if(key == 'search') {
                initialSearch = route.query.search;
            } else if(key != 'search'){
                let currentThing = route.query[key];
                cartStore.carts_filters[key] = '';
                cartStore.carts_filters[key] = currentThing;
            }

            initialFilters = cartStore.carts_filters;
            let param = route.query[key];
            cartStore.carts_filters[key] = (param == undefined) ? null : param;
        });
    }

    loadCarts(false).then(() => {
        isLoading.value = false;
    });

    if(cartStore.carts_filters.date_start && cartStore.carts_filters.date_end){
        initialFilters = {};
        initialFilters['date_range'] = cartStore.carts_filters.date_start + '@' + cartStore.carts_filters.date_end
    }

});

function search(r) {
    cartStore.carts_filters.search = r;
    loadCarts();
}

function filters(r) {
    Object.keys(r).forEach((key) => {
        cartStore.carts_filters[key] = r[key];
    });
    globalStore.loader('show');
    loadCarts();
}

const totalFilters = ref(0);

function filtersQty(r) {
    totalFilters.value = r;
}

let isChangingPage = false;
async function changePage(page) {
    if(isChangingPage) return;
    cartStore.carts_filters.page = page;
    globalStore.loader('show');
    isChangingPage = true;
    loadCarts(false).then(() => {
        isChangingPage = false;
        window.scrollTo({ top:0, left:0, behavior: "instant"});
    });
}

// eslint-disable-next-line
const exportUrl = ref(process.env.VUE_APP_API_URL + 'recovery/abandoned-carts/export/');
const exportParams = ref('');

async function loadCarts() {
    let usedParams = {};
    if(initialFilters){
        initialFilters.date_range = null
    }
    Object.keys(cartStore.carts_filters).forEach((key) => {
        if(cartStore.carts_filters[key] != null && cartStore.carts_filters[key] != '') {

            let currentParams = {
                ...usedParams,
                [key]: cartStore.carts_filters[key]
            };
            usedParams =  currentParams;
        }
    });

    router.replace({query: usedParams});
    exportParams.value = (usedParams) ? '?' + qs.stringify(usedParams, { arrayFormat: "brackets" }) : '';
    await cartStore.tryCarts(cartStore.carts_filters);
    globalStore.loader('hide');
    isLoading.value = false;
}

function watchCart(id){
    if(isLoading.value){
        return
    }
    cartStore.cart = cartStore.carts[id];
    globalStore.loader('show');
    cartStore.tryCart(id).then(() => {
        globalStore.loader('hide');
        router.push('/recovery/abandoned-carts/' + id);
    });
}

</script>

<style scoped>

.btn_filter button{
    margin-top: 28px;
}

.filters_qty_active{
    background-color: #5986e2;
    padding: 0px 6px;
    font-size: 12px;
    color: white;
    border-radius: 40px;
    margin-left: 9px;
}


.btn-filter::after {
    display: none !important;
}

small {
    display: block;
    color: #215eda;
    font-weight: 500;
    font-size: 80%;
    white-space: nowrap;
}

small span {
    cursor: pointer;
}

small span:hover {
    text-decoration: underline;
}

.light{
    color: #808080;
    font-weight: 400;
    cursor:text;
}

.light:hover{
    text-decoration: none;
}

</style>