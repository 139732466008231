<template>
    <div class="mb-3 form-group">

        <label v-if="label" :for="[multi ? 'multi-selected-' : ''] + props.id" :class="[error ? 'danger-label' : '']">{{ label }}</label>

        <template v-if="isDesktop()">

            <div :id="'select-box-container-' + props.id">

                <ul v-if="multi" :id="'multi-selected-' + props.id" class="form-select select-input multi" @click="selectInput">
                    <li v-for="i in response" :key="i" class="selected">{{ props.items[i] }} <span @click="removeItem(i)"><font-awesome-icon icon="fas fa-xmark" /></span></li>
                    <li class="field">
                        <input
                            :id="'multiple_search_' + props.id"
                            :value='search'
                            @input='updateSearch'
                            type="text"
                            @focus="openBox"
                            :required="!!required"
                        />
                    </li>
                    <span v-if="searchAndResponseIsEmpty" class="pholder">{{ placeholder }}</span>
                </ul>

                <input 
                    v-else
                    :disabled="disabled"
                    type="text"
                    :id="props.id"
                    :class="'form-select select-input ' + [error ? 'danger-border' : '']"
                    :placeholder="placeholder"
                    @focus="openBox"
                    :value='search'
                    @input='updateSearch'
                    :required="!!required"
                />

                <ul v-if="multiList === true" class="select-box" :id="'select-box-' + props.id">
                    <template v-if="itemsMultiListFirst || itemsMultiListSecond && Object.keys(itemsMultiListFirst).length > 0 && Object.keys(itemsMultiListSecond).length > 0">
                        <ul>
                            <li class="group-result">Páginas</li>
                            <li v-for="(i,key) in itemsMultiListFirst" :key="key" @click="selectItemPage(key)">{{ i.title }}</li>
                        </ul>
                        <ul>
                            <li class="group-result">Produtos</li>
                            <li v-for="(i,key) in itemsMultiListSecond" :key="key" @click="selectItem(key)">
                                {{ i.title }}
                                <BaseBadget v-if="i.tag" class="ms-1" color="#424242" :text=" i.tag" />
                            </li>
                        </ul>
                    </template>
                    <span class="message" v-else-if="search && ((!props.multi && !response) || props.multi)">Nenhum resultado encontrado para: <b>{{search}}</b></span>
                    <span class="message" v-else-if="!search">Todos os resultados já selecionados</span>
                </ul>

                <ul v-else class="select-box" :id="'select-box-' + props.id">
                    <template v-if="filteredList && Object.keys(filteredList).length > 0">
                        <li v-for="(i,key) in filteredList" :key="key" @click="selectItem(key)">{{i}}</li>
                    </template>
                    <span class="message" v-else-if="search && ((!props.multi && !response) || props.multi)">Nenhum resultado encontrado para: <b>{{search}}</b></span>
                    <span class="message" v-else-if="!search">Todos os resultados já selecionados</span>
                </ul>

            </div>

        </template>

        <template v-else>
            <select
                :class="'form-select select-input ' + [error ? 'danger-border' : '']"
                @input="updateInput"
                :required="!!required"
                :disabled="disabled == 1"
                :multiple="props.multi"
            >
                <option v-if="placeholder" :selected="(!props.multi)" disabled="disabled" value="">{{ placeholder }}</option>
                <option v-for="(item, key) in items" :key="key" :value="key" :selected="selectOptionOnMobile(key)">{{ item }}</option>
            </select>
        </template>

        <span v-if="!!error" class="invalid-feedback" :class="[error ? 'd-block' : '']" role="alert">{{ error }}</span>
    </div>
</template>

<script setup>
import { ref, computed, watch, onBeforeMount, onBeforeUnmount, onMounted } from "vue";

import { useGlobalStore } from "@/stores/global";
const global = useGlobalStore();

const props = defineProps({
    label: {
        type: String,
        required: false,
        default: null,
    },

    modelValue: {},

    placeholder: {
        type: String,
        required: false,
        default: null
    },

    items: {
        required: true,
    },

    page: {
        type: String,
        required: false
    },

    required: {
        type: Boolean,
        required: false,
        default: null,
    },

    id: {
        type: String,
        required: true
    },

    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },

    multi: {
        type: Boolean,
        required: false,
        default: null,
    },

    multiList: {
        type: Boolean,
        required: false,
        default: false,
    },

});

const boxIsOpen = ref(false);
const search = ref(null);
const response = ref(null);

onBeforeMount(() => {
    if(props.modelValue || (props.modelValue == 0 && props.modelValue == '0')) {
        if(!props.multi) {
            search.value = props.items[props.modelValue.toString()];
        }
        response.value = props.modelValue.toString();
        if(props.multi){
            response.value = (response.value) ? response.value.split(',') : [];
        }
    }
});

onMounted(() => {
    if(props.multi) {
        let input = document.getElementById('multiple_search_' + props.id);
        input.addEventListener('input', resizeInput, true);
        resizeInput.call(input);
    }
});

function resizeInput() {
    this.style.width = ((this.value.length + 1) * 8) + 'px';
}

function isDesktop() {
    let platform = window.navigator.platform;
    let mac = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    let win = ['Win32', 'Win64', 'Windows', 'WinCE'];
    let userAgent = window.navigator.userAgent;
    if ( (mac.indexOf(platform) !== -1) || (win.indexOf(platform) !== -1) || ( (/Linux/.test(platform)) && !(/Android/.test(userAgent)) ) ) {
        return true;
    }
    return false;
}

function updateSearch(e) {
    search.value = e.target.value;
    if(!props.multi) {
        response.value = null;
    }
}

const emit = defineEmits(["update:modelValue", "emitListType"]);

watch([response, boxIsOpen], ([e]) => {
    if(!props.multi){
        emit("update:modelValue", e);
    } else{
        emit("update:modelValue", response.value)
    }
    if(global.errors[props.page] && global.errors[props.page][props.id]) {
        delete global.errors[props.page][props.id];
    }
});


function updateInput(event) {

    if(props.multi) {

        var result = [];
        var options = event.target.options;
        var opt;

        for (var i=0, iLen=options.length; i<iLen; i++) {
            opt = options[i];

            if (opt.selected) {
                result.push(opt.value);
            }
        }
        emit("update:modelValue", result);
    }else {
        emit("update:modelValue", event.target.value);
    }
    if(global.errors[props.page] && global.errors[props.page][props.id]) {
        delete global.errors[props.page][props.id];
    }
}

function selectOptionOnMobile(key) {
    if (typeof props.modelValue === 'string' || props.modelValue instanceof String || props.modelValue == null || props.modelValue == '') {
        return (key == props.modelValue) ? true : false;
    }else {
        return (props.modelValue.includes(key)) ? true : false;
    }
}

const error = computed(function () {
    if(global.errors[props.page] && global.errors[props.page][props.id]) {
        return global.errors[props.page][props.id];
    }
    return null;
});

const filteredList = computed(function () {
    let list = {};

    if (search.value == null && response.value == null || !props.multi && response.value) {
        return props.items;
    }

    if(Object.keys(filteredList).length) {

        Object.keys(props.items).forEach((key) => {
            if(search.value && props.items[key].toLowerCase().includes(search.value.toLowerCase())) {
                list = addToList(key, props.items[key], list);
            }else if(!search.value) {
                list = addToList(key, props.items[key], list);
            }
        });
        return list;
    }
    return {};
});

const itemsMultiListFirst = computed(function(){
    let list = {};

    if (search.value == null && response.value == null || !props.multi && response.value) {
        return props.items.pages;
    }

    if(Object.keys(itemsMultiListFirst).length) {

        Object.keys(props.items.pages).forEach((key) => {
            if(search.value && props.items.pages[key].title.toLowerCase().includes(search.value.toLowerCase())) {
                list = addToList(key, props.items.pages[key], list);
            }else if(!search.value) {
                list = addToList(key, props.items.pages[key], list);
            }
        });
        return list;
    }
    return {};
})

const itemsMultiListSecond = computed(function(){
    let list = {};

    if (search.value == null && response.value == null || !props.multi && response.value) {
        return props.items.products;
    }

    if(Object.keys(itemsMultiListSecond).length) {

        Object.keys(props.items.products).forEach((key) => {
            if(search.value && props.items.products[key].title.toLowerCase().includes(search.value.toLowerCase())) {
                list = addToList(key, props.items.products[key], list);
            }else if(!search.value) {
                list = addToList(key, props.items.products[key], list);
            }
        });
        return list;
    }
    return {};
})

function addToList(key, item, list) {
    if(response.value && ((props.multi && response.value.indexOf(key) >= 0) || response.value == key)) {
        //jump
    }else {
        let currentItem = {
            ...list,
            [key]: item
        };
        list = currentItem;
    }
    return list;
}

function openBox(e) {
    boxIsOpen.value = true;
    e.target.focus();
    e.target.select();
}

watch(boxIsOpen, function (e) {
    if(e) {
        document.getElementById("select-box-" + props.id).style.display = "block";
        window.addEventListener('click', clickOutOfTheBox, true);
    }else {
        document.getElementById("select-box-" + props.id).style.display = "none";
        if(props.multi || (response.value == '' || response.value == [] || response.value == undefined)) {
            search.value = null;
        }
        window.removeEventListener('click', clickOutOfTheBox, true);
    }
});

function clickOutOfTheBox(e) {
    if (!document.getElementById('select-box-container-' + props.id).contains(e.target)){
        boxIsOpen.value = false;
    }
}

onBeforeUnmount(() => {
    window.removeEventListener('click', clickOutOfTheBox, true);
    window.removeEventListener('input', resizeInput, true);
});

const searchAndResponseIsEmpty = computed(function () {
    return ((search.value == null || search.value == '') && (response.value == null || !response.value.length)) ? true : false;
});

function selectItem(r) {
    if(props.multi) {
        if(response.value === null) {
            response.value = [r];
        }else {
            let res = response.value;
            res.push(r);
            response.value = res;
        }
        search.value = null;
        selectInput();
        } else if (props.multiList) {
            response.value = r.toString();
            if(props.items.products[r].tag) {
                search.value = props.items.products[r].title.toString() + '(' + props.items.products[r].tag.toString() +')';
                emit("emitListType", {typeValue : 'product', itemId : props.items.products[r].id})

            } else if(props.items.products[r].title){
                search.value = props.items.products[r].title.toString();

                emit("emitListType", {typeValue : 'product', itemId : props.items.products[r].id})
            }
            boxIsOpen.value = false;
        }else {
            response.value = r.toString();
            search.value = props.items[r].toString();
            boxIsOpen.value = false;
        }
}

function selectItemPage(r){
        response.value = r.toString();
        search.value = props.items.pages[r].title.toString();
        emit("emitListType", {typeValue : 'page', itemId : props.items.pages[r].id})
        boxIsOpen.value = false;
}

function removeItem(r) {
    let res = response.value.filter(e => e !== r);
    return response.value = (res.length) ? res : null;
}

function selectInput() {
    document.getElementById('multiple_search_' + props.id).focus();
}
</script>

<style scoped>
label {
    min-height: 21px;
    display: block;
    margin-block: 5px;
    font-size: 14px;
    text-align: left;
    font-weight: 500;
    color: #545454;
}

.select-input {
    box-sizing: border-box;
    height: 39px;
    box-shadow: none;
    color: #495057;
    border: 1px solid #d7d7d7;
    width: 100%;
    padding: 8px 15px;
    border-radius: 6px;
    color: #444;
    font-size: 14px;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
}

.select-input:focus {
    box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 8%) !important;
    border: 1px solid #b8b8b8 !important;
    outline: none !important;
}

.danger-border {
    border-color: #fd6d6d;
    color: #d60e0e;
    background-image:url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23fd6d6d%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
    box-shadow: 0px 0px 0px 3px #ffe7e7 !important
}

.danger-label {
    color: #d60e0e;
}

.form-group {
    position: relative;
}

.select-box {
    display:none;
    width: calc(100% - var(--bs-gutter-x));
    max-height: 262px;
    border: 1px solid #d7d7d7;
    position: absolute;
    left: 0;
    margin-left: calc(var(--bs-gutter-x) * .5);
    border-radius: 6px;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 10%);
    margin-top: 6px;
    background: #fff;
    padding: 0px !important;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 2;
    font-size: 14px !important;
    color: #545454 !important;
}

.group-result{
    display: list-item;
    font-weight: 700;
    cursor: default!important;
    color: #444;
}

.group-result:hover{
    background-color: white!important;
}

.select-box ul {
    padding: 0px;
}

.select-box > li, .select-box > ul li {
    cursor: pointer;
    padding: 10px 15px;
}

.select-box > li:hover, .select-box > ul li:hover {
    background: #f8f9fa;
}

.message {
    display: block;
    padding: 10px 15px;
}

.multi {
    height: auto;
    padding: 3px 25px 4px 15px;
    margin-bottom: 0px;
    position: relative;
    overflow: hidden;
}

.multi:focus-within {
    box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 11%) !important;
}

.multi input, .multi input:focus, .multi input:active {
    outline: none;
    border: none;
    width: 100%;
    position: relative;
    z-index: 2;
    background: transparent;
}

.multi .pholder {
    position: absolute;
    top: 8px;
    color: #7c7c7c;
    z-index: 1;
    left: 15px;
}

.multi .selected {
    margin: 3px 13px 3px -9px;
    padding: 2px 20px 2px 6px;
    border: 1px solid #aaa;
    max-width: 100%;
    border-radius: 20px;
    cursor: default;
    float:left;
    font-size: 12px;
    position: relative;
}

.multi .selected span {
    position: absolute;
    right: 2px;
    padding: 0px 4px;
    cursor: pointer;
    color: #888;
}

.multi .selected span:hover {
    color:#464646;
}

.multi .field {
    margin: 0;
    padding: 0;
    white-space: nowrap;
}

.multi li, .multi ul li {
    position: relative;
    overflow: hidden;
    float: left;
}

.multi li.field:last-child {
    margin-left: -5px !important;
}
 
.multi .field input {
    min-width: 25px !important;
    height: 30px;
    padding: 0;
    box-sizing: content-box;
}

.form-select:disabled {
    background-color: #e9ecef !important;
}

.danger-border::-webkit-input-placeholder {
  color: #d60e0e;
}

.danger-border:-moz-placeholder{
  color: #d60e0e;
}

.danger-border::-moz-placeholder{
  color: #d60e0e;
}

.danger-border:-ms-input-placeholder {
  color: #d60e0e;
}
</style>