<template v-if="reportStore.productors.length > 0">
    <div class="container px-2 px-lg-4">
        <BaseHeader :title="title">
        </BaseHeader>
        <BaseFilter removeSearchBar>
            <div class="item action btn-group">
                <BaseButton class="btn-filter btn-primary w-100" data-bs-toggle="dropdown" ariahashpopup="true" aria-expanded="true" data-bs-auto-close="outside">
                    <font-awesome-icon icon="far fa-filter" />
                    Filtros
                    <span class="filters_qty_active">{{ totalFilters }}</span>
                </BaseButton>
                <div class="dropdown-menu dropdown-menu-end py-0"  aria-labelledby="dropdownMenu">
                    <ReportsFilter @filters="filters" :initialFilters="initialFilters" @filtersQty="filtersQty"/>
                </div>
                <BaseButton class="btn-primary w-100 dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    Exportar
                </BaseButton>
                <div class="dropdown-menu" x-placement="bottom-start" aria-labelledby="dropdownMenu">
                    <a class="dropdown-item d-flex justify-content-center flex-column" target="_blank" :href="exportUrl + 'csv' + exportParams">CSV</a>
                    <a class="dropdown-item d-flex justify-content-center flex-column" target="_blank" :href="exportUrl + 'xls' + exportParams">XLS</a>
                </div>
            </div>
        </BaseFilter>
        <BaseTable class="no_top_radius" :columns="tableColumns" :paginate="paginate" :isLoading="isLoading" :qty="productors.length"  @changePage="changePage" :noActions="true">
            <tr v-for="(productor, key) in reportStore.productors" :key="key">
                <td width="10">
                    {{ productor.name }}
                    <small class="light">{{ productor.email }}</small>
                    <small class="light">{{ productor.document }}</small>
                </td>
                <td>
                    {{ productor.address }}, {{ productor.address_number }},
                    <br/>
                    {{ productor.neighborhood }}, {{ productor.city }} - {{ productor.state }},
                    <br/>
                    <template v-if="productor.complement">
                        Complemento: {{ productor.complement }}
                        <br/>
                    </template>
                    CEP: {{ globalStore.formatPostcode(productor.postcode) }}

                </td>
                <td>{{ globalStore.formatMoney(productor.amount) }}</td>
                <td>{{ globalStore.formatMoney(productor.amount_withdrawal) }}</td>
            </tr>
            <tr v-if="productors.length">
                <td width="35%" class="bold">Total</td>
                <td></td>
                <td class="bold">{{ globalStore.formatMoney(reportStore.getTotalProductorAmount()) }}</td>
                <td class="bold">{{ globalStore.formatMoney(reportStore.getTotalProductorWithdrawal()) }}</td>
                <td></td>
            </tr>
        </BaseTable>
    </div>
</template>
<script setup>
const title = {title: 'Relatório: Meus produtores', icon: 'fa-chart-simple'};
import ReportsFilter from '@/components/includes/reports/ReportsFilter';

import { ref, onBeforeMount, watch } from 'vue';
import { storeToRefs } from 'pinia';
import qs from "qs";

import { useReportStore } from '@/stores/report';
const reportStore = useReportStore();
const { productors, paginate } = storeToRefs(reportStore);

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();
const { defaultStore } = storeToRefs(globalStore);

const tableColumns = ['Produtor', 'Endereço', 'Comissão', 'Saque'];

const isLoading = ref(false);

import { useRouter, useRoute } from 'vue-router';
import BaseFilter from '@/components/ui/BaseFilter.vue';
const route = useRoute();
const router = useRouter();

let initialFilters = null;

onBeforeMount(() => {
    if(!globalStore.hasPermissionTo('report_productors')) {
        return router.push('/403');
    }

    if(reportStore.productors.length == undefined){
        isLoading.value = true;
    }


    if(route.query.page || route.query.date_start || route.query.date_end) {
        Object.keys(reportStore.productors_rep_filters).forEach((key) => {
            let currentThing = route.query[key];
            reportStore.productors_rep_filters[key] = '';
            reportStore.productors_rep_filters[key] = currentThing;
            
            initialFilters = reportStore.productors_rep_filters;
            let param = route.query[key];
            reportStore.productors_rep_filters[key] = (param == undefined) ? null : param;
        });
    }

    loadProductors().then(() => {
        isLoading.value = false;
    });

    if(reportStore.productors_rep_filters.date_start && reportStore.productors_rep_filters.date_end){
        initialFilters = {};
        initialFilters['date_range'] = reportStore.productors_rep_filters.date_start + '@' + reportStore.productors_rep_filters.date_end
    }
});

let isChangingPage = false;
async function changePage(page) {
    if(isChangingPage) return;
    reportStore.productors_rep_filters.page = page;
    globalStore.loader('show');
    isChangingPage = true;
    loadProductors().then(() => {
        isChangingPage = false;
        window.scrollTo({ top:0, left:0, behavior: "instant"});
    });
}

// eslint-disable-next-line
const exportUrl = ref(process.env.VUE_APP_API_URL + 'reports/productors/export/');

const exportParams = ref('');

let loadDefaultStore = false;
watch(defaultStore, function () {
    if(!loadDefaultStore) {
        loadDefaultStore = true;
        reportStore.productors_rep_filters.page = null;
        globalStore.loader('show');
        loadProductors().then(()=> {
            globalStore.loader('hide');
            loadDefaultStore = false;
        });
    }
});

async function loadProductors() {
    let usedParams = {};
    if(initialFilters){
        initialFilters.date_range = null
    }
    Object.keys(reportStore.productors_rep_filters).forEach((key) => {
        if(reportStore.productors_rep_filters[key] != null && reportStore.productors_rep_filters[key] != '') {

            let currentParams = {
                ...usedParams,
                [key]: reportStore.productors_rep_filters[key]
            };
            usedParams =  currentParams;
        }
    });

    router.replace({query: usedParams});
    exportParams.value = (usedParams) ? '?' + qs.stringify(usedParams, { arrayFormat: "brackets" }) : '';
    await reportStore.tryProductors(reportStore.productors_rep_filters);
    globalStore.loader('hide');
}

const totalFilters = ref(0);

function filtersQty(r) {
    totalFilters.value = r;
}

function filters(r) {
    Object.keys(r).forEach((key) => {
        reportStore.productors_rep_filters[key] = r[key];
    });
    globalStore.loader('show');
    loadProductors();
}

</script>

<style scoped>
.filters_qty_active{
    background-color: #5986e2;
    padding: 0px 6px;
    font-size: 12px;
    color: white;
    border-radius: 40px;
    margin-left: 9px;
}
.base-title .action {
    flex: 0 0 225px!important;
}

.btn-filter{
    margin-right: 10px;
}

.btn-filter::after {
    display: none !important;
}

.light{
    font-weight: 500;
    font-size: 80%;
    display: block;
}

.bold{
    font-weight: bold; 
}

</style>