<template>
    <div class="box_content">
        <div class="table-responsive">
            <table class="table mb-0">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(checkout, key) in checkouts" :key="key">
                        <td>{{ checkout.title }}</td>
                        <td class="actions">
                            <router-link data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Personalizar" :to="'checkouts/' + checkout.id + '/customization'"><font-awesome-icon icon="fas fa-paint-roller" /></router-link>
                            
                            <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Editar" @click="editCheckout(checkout.id)"><font-awesome-icon icon="fas fa-edit" /></span>
                            <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Remover" @click="openPopup(checkout.id)"><font-awesome-icon icon="fas fa-trash-alt" /></span>
                            <span class="dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true"><font-awesome-icon icon="fas fa-ellipsis-h" /></span>
                            <div class="dropdown-menu" x-placement="bottom-start" aria-labelledby="dropdownMenu">
                                <span class="dropdown-item d-flex justify-content-center flex-column" @click="tryDuplicateCheckout(checkout.id)">Duplicar</span>
                                <span class="dropdown-item d-flex justify-content-center flex-column" @click="goToCheckout(checkout.product_demo.url, checkout.url_param, checkout.display_type)" @click.middle="goToCheckout(checkout.product_demo.url, checkout.url_param, checkout.display_type)">Ver</span>
                                
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h6 v-if="!checkouts.length" class="text-center p-4 mb-0">Nenhum resultado encontrado</h6>
        </div>

        <div style="text-align:center;" class="p-4 divisor_top">
            <router-link :to="'/stores/' + store_id + '/checkouts/new'"> 
                <BaseButton class="btn-primary btn-lg">
                    Adicionar
                </BaseButton>
            </router-link>
        </div>
    </div>
    <router-view :productsList="productsList"></router-view>
    <BasePopup v-if="confirmAction" closeType="stayOnPage" width="400" @clickToClose="clickToClose">
        <div class="confirm_action">
            <div class="popup ">
                <p class="title">Atenção</p>
                <p class="text">Deseja remover este checkout?</p>
                <h6>Escreva a palavra "EXCLUIR" para confirmar.</h6>
                <BaseInput
                    label=''
                    type="text"
                    id="excludeTextConfirm"
                    v-model="excludeTextConfirm"
                    :page="route.name"
                    class="col-12"
                />
                <baseButton @click="clickToClose" style="width:110px" class="btn-secondary me-2">Cancelar</baseButton>
                <baseButton @click="deleteCheckout" style="width:150px" class="btn-success ms-2" :disabled="isLoading ? true : excludeTextConfirm == 'EXCLUIR' || excludeTextConfirm == 'excluir'  ? false : true">Sim, remover</baseButton>
            </div>
        </div> 
    </BasePopup>
</template>

<script setup>
import { computed, onBeforeMount, ref, shallowRef } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute, useRouter } from "vue-router";
import { useStoreStore } from '@/stores/store';
import { useGlobalStore } from '@/stores/global';

const route = useRoute();
const router = useRouter();

const storeStore = useStoreStore();
const { checkouts } = storeToRefs(storeStore);

const globalStore = useGlobalStore();

const store_id = computed (function () {
    return route.params.id;
});

let checkoutToDelete = null;
let isDeleting = false;
const confirmAction = shallowRef(null);
const excludeTextConfirm = ref(null)
const isLoading = ref(false);

const productsList = ref({
    0: 'Todos'
})

onBeforeMount(() =>{
    storeStore.tryStoreProducts(store_id.value).then(function(success) {
        if(success == true){
            for(let index = 0; index < storeStore.products.length; index++){
                productsList.value[storeStore.products[index].id] = storeStore.products[index].title
            }
        }
    })
})

async function deleteCheckout(){
    if(!isDeleting && !isLoading.value && checkoutToDelete && excludeTextConfirm.value == 'EXCLUIR' || excludeTextConfirm.value == 'excluir') {
        isDeleting = true;
        isLoading.value = true;
        globalStore.loader('show');
        await storeStore.tryDeleteCheckout(checkoutToDelete).then(function(success) {

            if(success){
                storeStore.tryCheckouts().then(function() {
                    globalStore.loader('hide');
                    isDeleting = false;
                    confirmAction.value = null;
                    router.push('/stores/' + store_id.value + '/checkouts')
                });
                isLoading.value = false;
            }else {
                globalStore.loader('hide');
                isDeleting = false;
            }
            isLoading.value = false;
            excludeTextConfirm.value = null
        }); 
    }
}

function editCheckout(id){
    globalStore.loader('show')
    storeStore.tryCheckout(id, store_id.value).then(() =>{
        router.push('/stores/' + store_id.value + '/checkouts/' + id);
        globalStore.loader('hide')
    })
}

function goToCheckout(pattern_url, url_param, type){
    if(url_param !== null && type === 'url'){
        let splitedPattern = pattern_url.split('?');
        let urlWithParam = splitedPattern[0] + '?c=' + url_param
        window.open(urlWithParam)
    } else {
        window.open(pattern_url)
    }
}

function tryDuplicateCheckout(checkout_id){
    globalStore.loader('show');
    storeStore.tryDuplicateCheckout(checkout_id).then(function(id_duplicated_campaign) {
        if(id_duplicated_campaign){
            globalStore.cleanObject(storeStore.checkout);
            storeStore.tryCheckouts().then(function() {
                globalStore.loader('hide');
            });
        } else {
            globalStore.loader('hide');
        }
    })
}

function openPopup(id) {
    checkoutToDelete = id
    confirmAction.value = true
    excludeTextConfirm.value = null;
}

function clickToClose() {
    confirmAction.value = null;
}

</script>