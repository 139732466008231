<template>
    <div v-if="isLoaded === true">
        <BasePopup closeType="stayOnPage" width="700" @clickToClose="clickToClose">
            <header>
            <font-awesome-icon icon="fa-solid  fa-flask" style="color: #215eda;"/>
            <span v-if="route.name == 'storeTestsAbEdit'" class="ms-2 align-middle">Editar teste A/B</span>
            <span v-else class="ms-2 align-middle">Novo teste A/B</span>
        </header>
        <div class="content">
        <form @submit.prevent="">
                <div class="row">
                    <BaseInput
                        class="col-12"
                        label="Título *"
                        id="title"
                        v-model="testAb.title"
                        :page="route.name"
                    />
                    <BaseSelect
                        class="col-12"
                        label="Produto *"
                        id="id_product"
                        :items="productsList"
                        v-model="testAb.id_product"
                        :page="route.name"
                    />
                    <BaseSelect
                        class="col-12"
                        label="Status *"
                        id="status"
                        :items="statusList"
                        v-model="testAb.status"
                        :page="route.name"
                    />
                    <label>Checkouts *</label>
                    <div v-for="(testAb, index) in testAb.options" :key="index" class="col-10">
                        <BaseSelect
                            class="col-12" 
                            :items="checkoutsItems"
                            :page="route.name"
                            :id="'option_id_checkout_' + index"
                            v-model="storeStore.testAb.options[index].id_checkout"
                            :disabled="route.name == 'storeTestsAbEdit'"
                        />
                    </div>
                    <div class="col-2" v-if="route.name == 'storeTestsAbCreate'">
                        <BaseButton @click="addMoreCheckout" class="btn-primary w-100"><font-awesome-icon icon="fa-solid  fa-plus"/></BaseButton>
                    </div>
                </div>
                <BaseButton @click="submit" class="mt-3 btn-primary w-100" :disabled="isLoading">
                    Salvar
                </BaseButton>
            </form>
        </div>
        </BasePopup>
    </div>
</template>

<script setup>
import { ref, onBeforeMount, computed, onMounted } from 'vue'
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router'
import { useGlobalStore } from '@/stores/global';
import { useStoreStore } from '@/stores/store';
import { useProductStore }  from '@/stores/products'

const route = useRoute();
const router = useRouter();

const isLoaded = ref(false);
const isLoading = ref(false);

let previousPage = router.options.history.state.back;
const store_id = computed (function () {
    return route.params.id;
});

const storeStore = useStoreStore();
const { testAb } = storeToRefs(storeStore);

const productStore = useProductStore();

const globalStore = useGlobalStore();

function clickToClose() {
    if(previousPage !== null){
        router.push(previousPage)
    } else{
        previousPage = route.path.substring(0, route.path.lastIndexOf("/"));
        router.push(previousPage)
    }
}

const productsList = ref({})

const statusList = ref({
    0: 'Desabilitado',
    1: 'Habilitado',
})

const checkoutsItems = ref({})

onBeforeMount(() => {
    delete globalStore.errors[route.name];

    if(route.name === 'storeTestsAbCreate'){
        globalStore.cleanObject(storeStore.testAb);
        storeStore.tryStoresCheckouts().then(() =>{
            for(let i = 0; i < storeStore.store_checkouts.length; i++){
                checkoutsItems.value[storeStore.store_checkouts[i].id] = storeStore.store_checkouts[i].title;
            }
        })
        productStore.tryProductsList(store_id.value).then(() =>{
            for(let i = 0; i < productStore.productslist.length; i++){
                productsList.value[productStore.productslist[i].id] = productStore.productslist[i].title;
            }
        });
        globalStore.loader('hide')
        isLoaded.value = true;
    } else {
        globalStore.loader('show')
        if(storeStore.testAb.id){
            productStore.tryProductsList(store_id.value).then(() =>{
                for(let i = 0; i < productStore.productslist.length; i++){
                    productsList.value[productStore.productslist[i].id] = productStore.productslist[i].title;
                }
            }).then(() =>{
                storeStore.tryStoresCheckouts().then(function(success){
                    if(success){
                        for(let i = 0; i < storeStore.store_checkouts.length; i++){
                            checkoutsItems.value[storeStore.store_checkouts[i].id] = storeStore.store_checkouts[i].title;
                        }
                            globalStore.loader('hide')
                            isLoaded.value = true; 

                    } else{
                        globalStore.loader('hide')
                        isLoaded.value = true; 
                    }
                })
            })
        } else{
                storeStore.tryTestAb(route.params.slug).then(function(success) {
                    if(success){
                        productStore.tryProductsList(store_id.value).then(() =>{
                            for(let i = 0; i < productStore.productslist.length; i++){
                                productsList.value[productStore.productslist[i].id] = productStore.productslist[i].title;
                            }
                        }).then(() =>{
                            storeStore.tryStoresCheckouts().then(function(success){
                                if(success){
                                    for(let i = 0; i < storeStore.store_checkouts.length; i++){
                                        checkoutsItems.value[storeStore.store_checkouts[i].id] = storeStore.store_checkouts[i].title;
                                    }
                                    globalStore.loader('hide');
                                    isLoaded.value = true;
                                }else{
                                    globalStore.loader('hide');
                                    isLoaded.value = true;
                                }
                            })
                        })
                    } else {
                        globalStore.loader('hide');
                        isLoaded.value = true;
                    }
                })
        }
    }
});

onMounted(() => {
    if(route.name === 'storeTestsAbCreate'){
        storeStore.testAb.options.push({id_checkout: null});
    }
});

function addMoreCheckout(){
    storeStore.testAb.options.push({id_checkout: null});

}

async function submit() {
    if(!isLoading.value){
        isLoading.value = true;
        globalStore.loader('show')
        
        if(route.name == 'storeTestsAbEdit'){
            let params = ['id', 'title', 'id_product', 'status'];

            globalStore.loader('show');
            storeStore.tryUpdateTestAb(params, route.name).then(function(success) {
                if(success){
                    storeStore.tryTestsAb(store_id.value);
                    router.push('/stores/' + store_id.value + '/tests-ab')
                }
                isLoading.value = false;
                globalStore.loader('hide');
            })
        }
        else if(route.name == 'storeTestsAbCreate'){
            let params = ['title', 'id_product', 'status', 'options'];

            globalStore.loader('show');
            storeStore.tryCreateTestAb(store_id.value, params, route.name).then(function(success) {
                if(success){
                    storeStore.tryTestsAb(store_id.value);
                    router.push('/stores/' + store_id.value + '/tests-ab')
                }
                isLoading.value = false;
                globalStore.loader('hide');
            })
        }
    }
}   

</script>

<style scoped>

.new_checkout_field{
    display: flex;
}

</style>