<template>
    <div class="container px-2 px-lg-4 pt-4" v-if="globalStore.hasPermissionTo('home')">
        <div class="row">
            <div v-if="useAccountStore().account.status.slug == 'pending'" class="col-12 mb-4">
                <div class="box_content p-4" id="account_pending">
                    <div class="d-flex">
                        <font-awesome-icon id="icon" class="mb-4 me-3" icon="fa-solid fa-clock" />
                        <div>
                            <h5>Estamos preparando tudo para você</h5>
                            <p class="mb-0">Seus documentos estão em processo de análise por nossa equipe de segurança. Este processo pode levar até 72 horas.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div id="sales_info" class="col-lg-3 col-12 mb-lg-0 mb-3">
                <div class="box_content">
                    <div v-if="infosExhibition == 'daily'" class="p-3">
                        <h5 class="info_title mb-1">Hoje</h5>
                        <h5 v-if="homeStore.home_infos.orders !== undefined" class="amount_total mb-0">
                            <span class="sensitive_information">
                                {{ globalStore.formatMoney([homeStore.home_infos.orders.today.amount ? homeStore.home_infos.orders.today.amount : 0]) }}
                            </span>
                            <BaseBadget
                                v-if="homeStore.home_infos.orders.percentage"
                                class="percentage_badget ms-1"
                                :color="[parseFloat(homeStore.home_infos.orders.percentage).toFixed(2) < 0.00 ? '#da3521' : parseFloat(homeStore.home_infos.orders.percentage).toFixed(2) > 0.00 ? '#28a745' : '#b1b1b1']"
                                :text="homeStore.home_infos.orders.percentage  + '%'"
                            />
                        </h5>
                        <h5 v-else class="sensitive_information amount_total mb-0">
                            R$ 0,00
                        </h5>
                        <p v-if="homeStore.home_infos.orders" class="info_title grey">
                            {{ homeStore.home_infos.orders.today.qty + [homeStore.home_infos.orders.today.qty == '1' ? ' venda' : ' vendas'] }}
                        </p>
                        <p v-else class="info_title">0 vendas</p>
                    </div>
                    <div v-else-if="infosExhibition == 'monthly'" class="p-3">
                        <h5 class="info_title mb-1">Este mês</h5>
                        <h5 v-if="homeStore.home_infos.orders !== undefined" class="amount_total mb-0">
                            <span class="sensitive_information">
                                {{ globalStore.formatMoney(homeStore.home_infos.orders.month.amount ? homeStore.home_infos.orders.month.amount : 0) }}
                            </span>
                            <BaseBadget
                                v-if="homeStore.home_infos.orders.percentage_month"
                                class="percentage_badget ms-1"
                                :color="[parseFloat(homeStore.home_infos.orders.percentage_month).toFixed(2) < 0.00 ? '#da3521' : parseFloat(homeStore.home_infos.orders.percentage_month).toFixed(2) > 0.00 ? '#28a745' : '#b1b1b1']"
                                :text="homeStore.home_infos.orders.percentage_month  + '%'"
                            />
                        </h5>
                        <h5 v-else class="sensitive_information amount_total mb-0">
                            R$ 0,00
                        </h5>
                        <p v-if="homeStore.home_infos.orders" class="info_title grey">
                            {{ homeStore.home_infos.orders.month.qty + [homeStore.home_infos.orders.month.qty == '1' ? ' venda' : ' vendas'] }}
                        </p>
                        <p v-else class="info_title">0 vendas</p>
                    </div>
                    <div class="divisor_bottom"></div>
                    <div class="switch_exhibition_infos" @click="switchInfos()">
                        <font-awesome-icon :icon="['fas', 'arrow-up-arrow-down']" />
                    </div>
                    <div v-if="infosExhibition == 'daily'" class="p-3">
                        <h5 class="info_title mb-1">Ontem</h5>
                        <p v-if="homeStore.home_infos.orders !== undefined" class="sensitive_information amount_total">
                          {{ globalStore.formatMoney([homeStore.home_infos.orders.yesterday.amount ? homeStore.home_infos.orders.yesterday.amount : 0]) }}
                        </p>
                        <p v-else class="sensitive_information amount_total">
                          R$ 0,00
                        </p>
                        <p v-if="homeStore.home_infos.orders" class="info_title grey">
                            {{ homeStore.home_infos.orders.yesterday.qty + [homeStore.home_infos.orders.yesterday.qty == '1' ? ' venda' : ' vendas'] }}
                        </p>
                        <p v-else class="info_title">0 vendas</p>
                    </div>
                    <div v-if="infosExhibition == 'monthly'" class="p-3">
                        <h5 class="info_title mb-1">Mês passado</h5>
                        <p v-if="homeStore.home_infos.orders !== undefined" class="sensitive_information amount_total">
                          {{ globalStore.formatMoney([homeStore.home_infos.orders.last_month.amount ? homeStore.home_infos.orders.last_month.amount : 0]) }}
                        </p>
                        <p v-else class="sensitive_information amount_total">
                          R$ 0,00
                        </p>
                        <p v-if="homeStore.home_infos.orders" class="info_title grey">
                            {{ homeStore.home_infos.orders.last_month.qty + [homeStore.home_infos.orders.last_month.qty == '1' ? ' venda' : ' vendas'] }}
                        </p>
                        <p v-else class="info_title">0 vendas</p>
                    </div>
                </div>
            </div>
            <div id="MetricsInfo" class="col-lg-6 col-12 mb-4 metrics_box">
                <div class="box_content">
                    <div class="box_spacing_metrics pb-0">
                        <div class="item_metrics col-lg-3 col-6 p-3">
                            <div class="info_svg_icon">
                                <font-awesome-icon icon="far fa-window"/>
                            </div>
                            <div class="info_infos">
                                <h5 class="info_title mb-1">Sessões</h5>
                                <h6 v-if="infosExhibition == 'daily'" class="info_value sensitive_information">{{ !homeStore.home_infos.metric.sessions ? '0' : homeStore.home_infos.metric.sessions}}</h6>
                                <h6 v-else-if="infosExhibition == 'monthly'" class="info_value sensitive_information">{{ !homeStore.home_infos.month_metric.sessions ? '0' : homeStore.home_infos.month_metric.sessions}}</h6>
                            </div>
                        </div>
                        <div class="item_metrics col-lg-3 col-6 p-3">
                            <div class="info_svg_icon">
                                <font-awesome-icon icon="fas fa-sync"/>
                            </div>
                            <div class="info_infos">
                                <h5 class="info_title mb-1">Conversão</h5>
                                <h6 v-if="infosExhibition == 'daily'"  class="info_value sensitive_information">{{ !homeStore.home_infos.metric.percent_conversion ? '0%' : homeStore.home_infos.metric.percent_conversion}}</h6>
                                <h6 v-else-if="infosExhibition == 'monthly'" class="info_value sensitive_information">{{ !homeStore.home_infos.month_metric.percent_conversion ? '0%' : homeStore.home_infos.month_metric.percent_conversion}}</h6>
                            </div>
                        </div>
                        <div class="item_metrics col-lg-3 col-6 p-3">
                            <div class="info_svg_icon">
                                <font-awesome-icon icon="fas fa-running"/>
                            </div>
                            <div class="info_infos">
                                <h5 class="info_title mb-1">Abandonado</h5>
                                <h6 v-if="infosExhibition == 'daily'"  class="info_value sensitive_information">{{ !homeStore.home_infos.metric.abandoned_carts ? 'R$ 0,00' : globalStore.formatMoney(homeStore.home_infos.metric.abandoned_carts)}}</h6>
                                <h6 v-else-if="infosExhibition == 'monthly'" class="info_value sensitive_information">{{ !homeStore.home_infos.month_metric.abandoned_carts ? 'R$ 0,00' : globalStore.formatMoney(homeStore.home_infos.month_metric.abandoned_carts)}}</h6>
                            </div>
                        </div>
                        <div class="item_metrics col-lg-3 col-6 p-3">
                            <div class="info_svg_icon">
                                <font-awesome-icon icon="fas fa-calculator"/>
                            </div>
                            <div class="info_infos">
                                <h5 class="info_title mb-1">Ticket médio</h5>
                                <h6 v-if="infosExhibition == 'daily'"  class="info_value sensitive_information">{{ !homeStore.home_infos.metric.average_ticket ? 'R$ 0,00' : globalStore.formatMoney(homeStore.home_infos.metric.average_ticket) }}</h6>
                                <h6 v-else-if="infosExhibition == 'monthly'" class="info_value sensitive_information">{{ !homeStore.home_infos.month_metric.average_ticket ? 'R$ 0,00' : globalStore.formatMoney(homeStore.home_infos.month_metric.average_ticket) }}</h6>
                            </div>
                        </div>
                    </div>
                    <div class="divisor_top"></div>
                    <div class="col-12">
                        <div style="height: 95px;">
                            <BarChart ref="childMethodBar" :chartStoreLoaded="homeStoreLoaded" />
                        </div>
                    </div>
                </div>
            </div>
            <div id="WalletInfo" class="mb-3 col-lg-3 col-12">
                <div class="box_content">
                    <div class="p-4">
                            <div class="d-flex mb-4">
                                <h2 class="metrics_title">
                                    <font-awesome-icon icon="fas fa-wallet" />
                                    Carteira
                                </h2>
                            </div>
                            <div class="mb-3">
                                <h6 class="info_title">Saldo total</h6>
                                    <p class="sensitive_information info_value mt-1">
                                        {{ homeStore.home_balance.amount_total != undefined  ? globalStore.formatMoney(homeStore.home_balance.amount_total) : 'R$ 0,00' }}
                                    </p>
                            </div>

                            <div class="d-flex justify-content-between align-items-end mb-5-negative divisor_top pt-3">
                                <div>
                                    <h6 class="mb-0 info_title">
                                        Saldo disponível
                                    </h6>
                                    <strong class="sensitive_information amount_available">
                                        {{ homeStore.home_balance.amount_available != undefined  ? globalStore.formatMoney(homeStore.home_balance.amount_available) : 'R$ 0,00'  }}
                                    </strong>
                                </div>

                                <BaseButton @click="toWithdrawal" class="btn-text d-block request_withdrawal pt-0 pe-0">
                                    <span class="me-2"> Sacar </span>
                                    <font-awesome-icon icon="fa-regular fa-arrow-right-long" style="vertical-align: middle" />
                                </BaseButton>
                            </div>

                            
                    </div>
                </div>
            </div>
            <div class="col-12 mb-4">
                <div class="box_content p-0">
                    <div class="p-4 header_sales">
                        <BaseMetricsBox>
                            <h2 class="metrics_title">
                                <font-awesome-icon icon="fas fa-chart-mixed" />
                                Vendas
                            </h2>
                            <div v-if="homeStoreLoaded == 'true'" class="header_actions sales_datepicker">
                                <BaseDatePicker id="SecondSalesDatepicker" :month=true hideInputIcon
                                    circle_background="1AB4D7" class="me-2" v-model="homeStore.home_chart.last_month.date"
                                    @formatMonthEvent="formatMonth('last_month')" @loadLastInfos="loadChartClear('thisMonth')"
                                    :storeLoaded="true" :loadedLastMonthPicker="true" :secondLoadChart="getChartLoaded" />
                                <BaseDatePicker  id="FirstSalesDatepicker" :month=true hide-input-icon hideInputIcon
                                    circle_background="1A75D7" v-model="homeStore.home_chart.this_month.date"
                                    @formatMonthEvent="formatMonth('this_month')" @loadThisInfos="loadChartClear('lastMonth')"
                                    :storeLoaded="true" :loadedThisMonthPicker="true" :secondLoadChart="getChartLoaded" />
                            </div>
                        </BaseMetricsBox>
                    </div>
                    <div>
                        <LineChart v-if="homeStoreLoaded == 'true'" :chart="homeStore.home_chart"
                            :chart_params="chartparams" ref="childMethod" :chartStoreLoaded="true" />
                        <LineChart v-else ref="childMethod" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container px-2 px-lg-4 pt-4" v-else>
        <h3>Olá, {{ auth.name }}</h3>
    </div>
</template>

<script setup>
import LineChart from '@/components/includes/home/LineChart.vue'
import BarChart from '@/components/includes/home/BarChart.vue'
import { ref, onBeforeMount, onMounted, onBeforeUnmount, watch } from "vue";
import { useRouter, useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useGlobalStore } from '@/stores/global';
import { useHomeStore } from '@/stores/home';
import { useWithdrawalStore } from '@/stores/withdrawal'
import { useAccountStore } from '@/stores/account';

const router = useRouter();
const route = useRoute();

const globalStore = useGlobalStore();
const { defaultStore } = storeToRefs(globalStore);

const homeStore = useHomeStore();

const withdrawalStore = useWithdrawalStore();

import { useAuthStore } from "@/stores/auth";
const auth = useAuthStore();

const params = ref({
    date_range: null,
    home_informations: null,
});

const chartparams = ref({
    chart_this_month: null,
    chart_last_month: null,
})

const infoparams = ref({
    date_start: null,
    date_end: null,
})

const today = new Date();

const childMethod = ref(null);
const childMethodClear = ref(null);
const childMethodBar = ref(null);
const homeStoreLoaded = ref(null);
const getChartLoaded = ref(false)
const todayDate = ref(null)
const infosExhibition = ref('daily')
var initialFilters = null;

onBeforeMount(async () => {
    globalStore.cleanObject(homeStore.home_infos);
    homeStore.home_infos.metric = {
        sessions: null,
        percent_conversion: null,
        average_ticket: null,
        abandoned_carts: null,
    }
    
    if(!globalStore.hasPermissionTo('home')) {
        return;
    }

    if(route.query.date_start || route.query.date_end) {

        Object.keys(infoparams.value).forEach((key) => {
            let currentThing = route.query[key];
            infoparams.value[key] = '';
            infoparams.value[key] = currentThing;
            
            initialFilters = infoparams.value;
            let param = route.query[key];
            infoparams.value[key] = (param == undefined) ? null : param;
        });
    } 
    
    if(infoparams.value.date_start && infoparams.value.date_end){
        params.value.date_range = infoparams.value.date_start + '@' + infoparams.value.date_end
    }

    if(!infoparams.value.date_start && !infoparams.value.date_end){
        setTodayDate()
    }

    setTimeout(() => {
        globalStore.loader('show');
        homeStore.tryHomeBalance();
        homeStore.tryHomeInfos(infoparams.value).then(() => {
            homeStoreLoaded.value = 'true';
            globalStore.loader('hide');
        });
    })
});

onMounted(() =>{
    if(initialFilters){
        childMethodClear.value.resizeDayPicker();
        initialFilters.date_range = null
    }
})

onBeforeUnmount(() => {
    clearInterval(intervalGets)
})

function setTodayDate() {
    let getFullYear = today.getFullYear();
    let getMonth = today.getMonth() + 1;
    let getDate = today.getDate();

    if (getDate <= 9) {
        infoparams.value.date_start = getFullYear + '-' + getMonth + '-0' + getDate
        infoparams.value.date_end = getFullYear + '-' + getMonth + '-0' + getDate
        todayDate.value = getFullYear + '-' + getMonth + '-0' + getDate
        if (getMonth <= 9) {
            infoparams.value.date_start = getFullYear + '-0' + getMonth + '-0' + getDate
            infoparams.value.date_end = getFullYear + '-0' + getMonth + '-0' + getDate
            todayDate.value = getFullYear + '-0' + getMonth + '-0' + getDate
        }
    }

    if (getMonth <= 9) {
        infoparams.value.date_start = getFullYear + '-0' + getMonth + '-' + getDate
        infoparams.value.date_end = getFullYear + '-0' + getMonth + '-' + getDate
        todayDate.value = getFullYear + '-0' + getMonth + '-' + getDate
        if (getDate <= 9) {
            infoparams.value.date_start = getFullYear + '-0' + getMonth + '-0' + getDate
            infoparams.value.date_end = getFullYear + '-0' + getMonth + '-0' + getDate
        }
    }
}

function formatMonth(selected_month) {
    setTimeout(() => {
        let selectedMonthSplitted = null;
        if(selected_month == 'this_month'){
            selectedMonthSplitted =  homeStore.home_chart.this_month.date.split('-');
        } else {
            selectedMonthSplitted = homeStore.home_chart.last_month.date.split('-');
        }

        if (selectedMonthSplitted[1].length <= 2) {
            let selectedMonthFormated = selectedMonthSplitted[1] + '-' + selectedMonthSplitted[0];
            if(selected_month == 'this_month'){
                chartparams.value.chart_this_month =  selectedMonthFormated;
            } else {
                chartparams.value.chart_last_month = selectedMonthFormated;
            }
            loadChartHome();
        } else {
            loadChartHome();
        }
    }, 10)
}

async function loadChartHome() {
    let usedParams = {};
    globalStore.loader('show');
    Object.keys(chartparams.value).forEach((key) => {
        if (chartparams.value[key] != null && chartparams.value[key] != '') {

            let currentParams = {
                ...usedParams,
                [key]: chartparams.value[key]
            };
            usedParams = currentParams;
        }
    });
    router.replace({ query: usedParams });
    await homeStore.tryHomeChart(chartparams.value);
    childMethod.value.updateChart(defaultStore._object.defaultStore.id);
    globalStore.loader('hide');
}

async function loadChartClear(monthPicker) {
    globalStore.loader('show');
    if(monthPicker === 'thisMonth'){
        chartparams.value.chart_last_month = '12-2032';
    } else if(monthPicker === 'lastMonth'){
        chartparams.value.chart_this_month = '12-2032';
    }
    loadChartHome()
    childMethod.value.updateChartClear(monthPicker);
    globalStore.loader('hide')
}

if(globalStore.hasPermissionTo('home')) {
    var intervalGets = setInterval(function (){
        if(!document.hidden && route.name == 'home'){
            infoparams.value.id = defaultStore._object.defaultStore.id;
            chartparams.value.id = defaultStore._object.defaultStore.id;
            homeStore.tryHomeBalance();
            childMethodBar.value.updateChart(defaultStore._object.defaultStore.id);
            homeStore.tryHomeChart(chartparams.value);
            if(infoparams.value.date_start == todayDate.value && infoparams.value.date_end == todayDate.value){
                homeStore.tryHomeInfos(infoparams.value);
            }
        }
    },10000);
}

let loadDefaultStore = false;
watch(defaultStore, function () {
    if (!loadDefaultStore) {
        loadDefaultStore = true;
        infoparams.value.id = defaultStore._object.defaultStore.id;
        chartparams.value.id = defaultStore._object.defaultStore.id;
        globalStore.loader('show');
        homeStore.tryHomeBalance(defaultStore._object.defaultStore.id);
        childMethodBar.value.updateChart(defaultStore._object.defaultStore.id);
        homeStore.tryHomeInfos(infoparams.value);
        loadChartHome().then(() => {
            globalStore.loader('hide');
            loadDefaultStore = false;
        });
    }
});

function toWithdrawal() {
    globalStore.loader('show')
    withdrawalStore.tryWalletWithdrawal().then(() => {
        globalStore.loader('hide')
        router.push("/withdrawals/request")
    })
}

function switchInfos(){
    if(infosExhibition.value == 'daily'){
        infosExhibition.value = 'monthly';
    } else if(infosExhibition.value == 'monthly'){
        infosExhibition.value = 'daily'
    } else {
        infosExhibition.value = 'daily';
    }
}

</script>

<style scoped>
.box_content {
    margin-bottom: 0px;
}

:deep(.title h2) {
    font-weight: 500;
    color: #808080;
}

.header_actions {
    float: right;
    margin-top: -35px;
    background: transparent;
    white-space: nowrap;
}

#DayPicker > .form-group{
    width: fit-content;
}

#sales_info .amount_total {
    width: 75px;
    margin-bottom: 0px!important;
    display: inline!important;
}

.sensitive_information{
    display: inline!important;
}

.switch_exhibition_infos{
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #ddd;
    text-align: center;
    position: relative;
    margin: -21px 20px -19px auto;
    background-color: white;
}

.switch_exhibition_infos svg{
    fill: #215eda;
    color: #215eda;
    font-size: 16px;
    margin-top: 10px;
}

.switch_exhibition_infos:hover{
    background-color: #f5f5f5;
}

#sales_info .amount_total .percentage_badget{
    top: -3px;
    position: relative;
}

#MetricsInfo .box_spacing_metrics {
    display: flex!important;
    flex-wrap: wrap!important;
}

#sales_info h6{
    margin-bottom: 0px;
}

.box_spacing_metrics {
    display: flex;
}

.item_metrics{
    width: 25%;
    border-left: 1px solid #ddd;
    padding-bottom: 13px;
    padding-left: 15px;
}

.item_metrics:first-child{
    border-left: none;
}

.metrics_amount_picker:deep(input::placeholder) {
    font-family: Poppins;
    font-size: 14px;
    padding-left: 5px;
    position: absolute;
    margin-top: 1px;
    font-weight: 500;
}

#DayPicker :deep(#size-calibration) {
    position: absolute;
}

#DayPicker :deep(.dp__input){
    border-radius: 10px;
}

.metrics_amount_picker :deep(.dp__input_wrap) {
    width: 85px;
}

.amount_total{
    font-weight: 600;
}

.amount_available {
    font-weight: 600;
    font-size: 18px;
    white-space: nowrap;
}

.circle {
    margin-top: 5px;
    margin-right: 10px;
    width: 10px !important;
    height: 10px !important;
    border-radius: 10px !important;
}
.metrics_title {
    display: inline-block;
    font-size: 18px;
    margin: 0;
    font-weight: 500;
}

.metrics_title svg {
    margin-right: 9.5px;
    color: #215eda;
    width: 23px;
    height: 18px !important;
}

:deep(.info_value){
    font-weight: 500 !important;
}

.info_infos{
    max-width: 125px;
}

.info_infos h6{
    width: 100%;
}

.info_svg_icon{
    font-size: 14px;
    color: #215eda;
    border-radius: 50px;
    margin-left: 0;
    background-color: #ffffff;
    text-align: center;
    align-items: center;
    display: flex;
    padding-bottom: 1px;
    margin-bottom: 10px;
}

.info_title{
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 500;
}

.info_title.grey {
    font-weight: 400;
    color: #808080;
}

.info_value{
    font-weight: 600;
    font-size: 14px;
    margin: 0;
    display: block !important;
    color: inherit;
    line-height: 14px;
}

.sales_datepicker {
    display: flex;
    margin-bottom: -5px;
    margin-right: 20px;
}

.sales_datepicker :deep(.circle) {
    position: relative;
    top: 36px;
    right: -30px;
    z-index: 9;
}

#FirstSalesDatepicker, #SecondSalesDatepicker {
    width: min-content;
    margin-top: 0px !important;
    margin-bottom: 16px;
}

.sales_datepicker :deep(.dp__input_wrap) {
    padding: 6px 0px 6px 20px;
}

.sales_datepicker:deep(input) {
    border-radius: 10px;
    width: 100%;
    border: 0;
    padding-left: 30px;
}

#WalletInfo .info_value{
    width: fit-content;
    padding-right: 10px;
}

#account_pending {
    background-color: #f6c430;
    border: 0;
}

#account_pending svg {
   font-size: 20px;
}

#payment_chart {
    position: relative;
    top: 5px;
    height: 100px;
}

#payment_chart #percentage_icons .item {
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
    white-space: nowrap;
}

#payment_chart #percentage_icons p {
    margin-bottom: 0px
}

.access_tests_ab{
    cursor: pointer;
}

.btn-text {
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    font-weight: 500;
    color: #215eda;
}

@media screen and (max-width: 992px) {
    .box_spacing_metrics {
        padding-left: 20px;
    }

    .title h2 {
        width: 100%;
        text-align: center;
    }

    #DayPicker > .form-group{
        width: auto;
    }

    #MetricsInfo .box_content{
        height: auto;
    }
    .item_metrics {
        padding-left: 5px!important;
        width: 50%;
        border-left: none!important;        
    }

    .metrics_amount_picker,
    .metrics_amount_picker :deep(.dp__input_wrap),
    .metrics_amount_picker :deep(.dp__input) {
        width: 100% !important;
        text-align: center;
    }

    .metrics_amount_picker :deep(.dp__input_icon) {
        left: 90% !important;
    }

    .header_actions {
        width: 100%;
        margin-top: 15px;
    }

    .header_sales{
        height: 130px;
    }

    .sales_datepicker {
        margin-top: -10px;
        margin-bottom: 15px;
    }

    .sales_datepicker :deep(.dp__main > div:first-child) {
        width: fit-content;
    }

    .sales_datepicker :deep(.dp__input) {
        width: 100%;
    }

    #DayPicker :deep(#size-calibration) {
        position: static;
    }

}
  @media screen and (max-width: 375px) {

    .header_sales{
        height: 225px;
    }

    .sales_datepicker{
        display: block;
    }

    #FirstSalesDatepicker, #SecondSalesDatepicker{
        margin-left: auto!important;
        margin-right: auto!important;
        margin-bottom: 0px;
    }

}


.p-3 {
    padding: 20px !important;
}

#sales_info .info_title {
    line-height: 15px;
}

.mb-5-negative {
    margin-bottom: -9px;
}
</style>