<template>
    <div class="box_content">
        <div class="table-responsive">
            <table class="table mb-0">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Código</th>
                        <th>Utilizações</th>
                        <th>Data</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(coupon, key) in coupons" :key="key">
                        <td>{{ coupon.id }}</td>
                        <td>{{ coupon.code }}</td>
                        <td>{{ coupon.utilizations }} / {{ coupon.limit_utilization }}</td>
                        <td>{{ coupon.created_at }}</td>

                        <td class="actions">
                            <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Editar" @click="editCoupon(coupon.id)"><font-awesome-icon icon="fas fa-edit" /></span>
                            <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Remover" @click="openPopup(coupon.id)"><font-awesome-icon icon="fas fa-trash-alt" /></span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h6 v-if="!coupons.length" class="text-center p-4 mb-0">Nenhum resultado encontrado</h6>
        </div>
        <!-- add row table quando dados de cupon à receber-->
        <div style="text-align:center;" class="p-4 divisor_top">
            <router-link :to="'/stores/' + store_id + '/coupons/new'"> 
                <BaseButton class="btn-primary btn-lg">
                    Adicionar
                </BaseButton>
            </router-link>
        </div>
    </div>
    <router-view :productsList="productsList"></router-view>
    <BasePopup v-if="confirmAction" closeType="stayOnPage" width="400" @clickToClose="clickToClose">
        <div class="confirm_action">
            <div class="popup ">
                <p class="title">Atenção</p>
                <p>Deseja remover este cupom?</p>
                <h6>Escreva a palavra "EXCLUIR" para confirmar.</h6>
                <BaseInput
                    label=''
                    type="text"
                    id="excludeTextConfirm"
                    v-model="excludeTextConfirm"
                    :page="route.name"
                    class="col-12"
                />
                <baseButton @click="clickToClose" style="width:110px" class="btn-secondary me-2">Cancelar</baseButton>
                <baseButton @click="deleteCoupon" style="width:150px" class="btn-success ms-2" :disabled="isLoading ? true : excludeTextConfirm == 'EXCLUIR' || excludeTextConfirm == 'excluir'  ? false : true">Sim, remover</baseButton>
            </div>
        </div> 
    </BasePopup>
</template>

<script setup>
import { computed, onBeforeMount, ref, shallowRef } from 'vue'
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from "vue-router";
import { useGlobalStore } from '@/stores/global';
import { useStoreStore } from '@/stores/store';

const route = useRoute();
const router = useRouter();

const storeStore = useStoreStore();
const { coupons } = storeToRefs(storeStore);

const globalStore = useGlobalStore();

const store_id = computed (function () {
    return route.params.id;
});

let couponToDelete = null;
let isDeleting = false;
const confirmAction = shallowRef(null)
const excludeTextConfirm = ref(null)
const isLoading = ref(false);

const productsList = ref({
    0: 'Todos'
})

onBeforeMount(() =>{
    storeStore.tryStoreProducts(store_id.value).then(function(success) {
        if(success == true){
            for(let index = 0; index < storeStore.products.length; index++){
                productsList.value[storeStore.products[index].id] = storeStore.products[index].title
            }
        }
    })
})

function editCoupon(id){
    globalStore.loader('show')
    storeStore.tryCoupon(id, store_id.value).then(() =>{
        router.push('/stores/' + store_id.value + '/coupons/' + id)
        globalStore.loader('hide')
    })
}

async function deleteCoupon(){
    if(!isDeleting && !isLoading.value && couponToDelete) {
        isLoading.value = true;
        globalStore.loader('show');
        await storeStore.tryDeleteCoupon(couponToDelete).then(function(success) {
            isDeleting = false;
            if(success){
                storeStore.tryCoupons().then(function() {
                    globalStore.loader('hide');
                    isDeleting = false;
                    router.push('/stores/' + store_id.value + '/coupons');
                    confirmAction.value = null;
                });
                isLoading.value = false;
            }else {
                globalStore.loader('hide');
                isDeleting = false;
                isLoading.value = false;
            }
            excludeTextConfirm.value = null;
        }); 
    }
}

function openPopup(id) {
    couponToDelete = id
    confirmAction.value = true;
    excludeTextConfirm.value = null;
}

function clickToClose() {
    confirmAction.value = null;
}

</script>