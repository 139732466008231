<template>
    <div class="container px-2 px-lg-4">
        <BaseHeader :title="title">
            <router-link v-if="globalStore.hasPermissionTo('new_campaign')" to="/campaigns/create" class="item action btn-group">
                <baseButton  class="btn btn-primary w-100">Nova</baseButton>
            </router-link>
        </BaseHeader>
        
        <BaseFilter :title="title" @search="search" :initialSearch="initialSearch">
            <div class="item action btn-group">
                <baseButton class="btn-filter btn-primary w-100" data-bs-toggle="dropdown" ariahashpopup="true" aria-expanded="true" data-bs-auto-close="outside">
                    <font-awesome-icon icon="far fa-filter" />
                    Filtros
                    <span class="filters_qty_active">{{ totalFilters }}</span>
                </baseButton>
                <div class="dropdown-menu dropdown-menu-end py-0"  aria-labelledby="dropdownMenu">
                    <FilterCampaigns @filters="filters" :initialFilters="initialFilters" @filtersQty="filtersQty"/>
                </div>
            </div>
        </BaseFilter>

        <BaseTable class="no_top_radius" :columns="tableColumns" :paginate="paginate" :isLoading="isLoading" :qty="campaigns.length" @changePage="changePage">
            <tr v-for="(campaign, key) in campaigns" :key="key">
                <td width="10">{{ campaign.id }}</td>
                <td>{{ campaign.title }}</td>
                <td>{{ campaign.access }}</td>
                <td>{{ campaign.conversion }}</td>
                <td>{{ globalStore.formatDate(campaign.created_at) }}</td>
                <td><BaseStoreDot :color="campaign.store.color" :title="campaign.store.title" /></td>
                <td class="actions">
                    <span @click="copyURL(campaign.url)" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip :title="tooltipText"><font-awesome-icon icon="fas fa-link" /></span>
                    <router-link :to="'/campaigns/'+ campaign.id" v-if="globalStore.hasPermissionTo('edit_campaign')" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Editar"><font-awesome-icon icon="fas fa-edit" /></router-link>
                    <span  v-if="globalStore.hasPermissionTo('duplicate_campaign') || globalStore.hasPermissionTo('destroy_campaign')" class="dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true"><font-awesome-icon icon="fas fa-ellipsis-h" /></span>
                    <div class="dropdown-menu" x-placement="bottom-start" aria-labelledby="dropdownMenu">
                        <span v-if="globalStore.hasPermissionTo('duplicate_campaign')" class="dropdown-item d-flex justify-content-center flex-column" @click="duplicateCampaign(campaign.id)">Duplicar</span>
                        <span v-if="globalStore.hasPermissionTo('destroy_campaign')" class="dropdown-item d-flex justify-content-center flex-column" @click="openPopup(campaign.id)">Remover</span>
                    </div>
                </td>
            </tr>
        </BaseTable>
        <router-view></router-view>
        <BasePopup v-if="confirmAction" closeType="stayOnPage" width="400" @clickToClose="clickToClose">
            <div class="confirm_action">
                <div class="popup">
                    <p class="title">Atenção</p>
                    <p class="text">Deseja remover essa campanha?</p>
                    <h6>Escreva a palavra "EXCLUIR" para confirmar.</h6>
                    <BaseInput
                        label=''
                        type="text"
                        id="excludeTextConfirm"
                        v-model="excludeTextConfirm"
                        :page="route.name"
                        class="col-12"
                    />
                    <baseButton @click="clickToClose" style="width:110px" class="btn-secondary me-2">Cancelar</baseButton>
                    <baseButton @click="deleteCampaign" style="width:150px" class="btn-success ms-2" :disabled="isLoading ? true : excludeTextConfirm == 'EXCLUIR' || excludeTextConfirm == 'excluir' ? false : true">Sim, remover</baseButton>
                </div>
            </div> 
        </BasePopup>
    </div>
</template>
<script setup>
const title = {title: 'Campanhas', icon: 'fa-bullhorn'};
import { ref, onBeforeMount, watch, shallowRef } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouter, useRoute } from 'vue-router';
import { useGlobalStore } from '@/stores/global';
import { useCampaignStore } from '@/stores/campaign';
import BaseFilter from '@/components/ui/BaseFilter';
import FilterCampaigns from "@/components/includes/campaigns/FilterCampaigs.vue"

const campaignStore = useCampaignStore();
const { campaigns, paginate } = storeToRefs(campaignStore);

const globalStore = useGlobalStore();
const { defaultStore } = storeToRefs(globalStore);

const tableColumns = ['ID', 'Título', 'Acessos', 'Conversão', 'Data', 'Loja'];

const isLoading = ref(false);

const confirmAction = shallowRef(null);

const route = useRoute();
const router = useRouter();

let initialFilters = null;
let initialSearch = null;

const excludeTextConfirm = ref(null)

onBeforeMount(() => {
    if(!globalStore.hasPermissionTo('campaigns')) {
        return router.push('/403');
    }

    if(campaignStore.campaigns.length == undefined){
        isLoading.value = true;
    }

    if(route.query.search || route.query.page || route.query.date_start || route.query.date_end) {

        Object.keys(campaignStore.campaigns_filters).forEach((key) => {
            if(key == 'search') {
                initialSearch = route.query.search;
            } else if(key != 'search'){
                let currentThing = route.query[key];
                campaignStore.campaigns_filters[key] = '';
                campaignStore.campaigns_filters[key] = currentThing;
            }

            initialFilters = campaignStore.campaigns_filters;
            let param = route.query[key];
            campaignStore.campaigns_filters[key] = (param == undefined) ? null : param;
        });
    }

    loadCampaign().then(() => {
        isLoading.value = false;
    });

    if(campaignStore.campaigns_filters.date_start && campaignStore.campaigns_filters.date_end){
        initialFilters = {};
        initialFilters['date_range'] = campaignStore.campaigns_filters.date_start + '@' + campaignStore.campaigns_filters.date_end
    }

});

function search(r) {
    campaignStore.campaigns_filters.search = r;
    campaignStore.campaigns_filters.page = null;
    loadCampaign();
}

function filters(r) {
    Object.keys(r).forEach((key) => {
        campaignStore.campaigns_filters[key] = r[key];
    });
    globalStore.loader('show');
    loadCampaign()
}

const totalFilters = ref(0);

function filtersQty(r) {
    totalFilters.value = r;
}

let isChangingPage = false;
async function changePage(page) {
    if(isChangingPage) return;
    campaignStore.campaigns_filters.page = page;
    globalStore.loader('show');
    isChangingPage = true;
    loadCampaign().then(() => {
        isChangingPage = false;
        window.scrollTo({ top:0, left:0, behavior: "instant"});
    });
}

let loadDefaultStore = false;
watch(defaultStore, function () {
    if(!loadDefaultStore) {
        loadDefaultStore = true;
        campaignStore.campaigns_filters.page = null;
        globalStore.loader('show');
        loadCampaign().then(()=> {
            globalStore.loader('hide');
            loadDefaultStore = false;
        });
    }
});


async function loadCampaign() {
    let usedParams = {};
    Object.keys(campaignStore.campaigns_filters).forEach((key) => {
        if(campaignStore.campaigns_filters[key] != null && campaignStore.campaigns_filters[key] != '') {

            let currentParams = {
                ...usedParams,
                [key]: campaignStore.campaigns_filters[key]
            };
            usedParams =  currentParams;
        }
    });

    router.replace({query: usedParams});
    await campaignStore.tryCampaigns(campaignStore.campaigns_filters);
    globalStore.loader('hide');
}

let isDeleting = false;
const current_page = ref({
    'page': null
});

let campaignToDelete = null;
async function deleteCampaign() {
    if(!isDeleting && !isLoading.value && campaignToDelete && excludeTextConfirm.value == 'EXCLUIR' || excludeTextConfirm.value == 'excluir') {
        isDeleting = true;
        isLoading.value = true;
        globalStore.loader('show');
        current_page.value.page = campaignStore.paginate.current_page
        await campaignStore.tryDeleteCampaign(campaignToDelete).then(() => {
            campaignStore.tryCampaigns(campaignStore.campaigns_filters).then(()=>{
                if(campaignStore.campaigns.length > 1){
                    campaignStore.tryCampaigns(campaignStore.campaigns_filters);
                    isLoading.value = false;
                    confirmAction.value = null;
                    globalStore.loader('hide');
                    isDeleting = false;   
                }else {
                    current_page.value.page = current_page.value.page - 1;
                    router.replace({query: current_page.value});
                    campaignStore.tryCampaigns(campaignStore.campaigns_filters).then(() =>{
                        globalStore.loader('hide');
                        isLoading.value = false;
                        isDeleting = false;   
                        confirmAction.value = null;
                    })
                }    
            });
            excludeTextConfirm.value = null;
        }); 
    }
}

const paramsredirect = ref({
    'page': null
});

async function duplicateCampaign(campaign_id){
    globalStore.loader('show');
    isLoading.value = true
    campaignStore.tryDuplicateCampaign(campaign_id).then(function(id_duplicated_campaign) {
        globalStore.cleanObject(campaignStore.campaign);
        paramsredirect.value.page = campaignStore.paginate.last_page
        
        router.push('/campaigns/' + id_duplicated_campaign)
    })
}

function openPopup(campaign) {
    campaignToDelete = campaign
    confirmAction.value = true
    excludeTextConfirm.value = null;
}

function clickToClose() {
    confirmAction.value = null;
}

var tooltipText = "Copiar Link"

function copyURL(url) {

    const tooltipInner = document.querySelector('.tooltip-inner')
    tooltipInner.innerHTML = 'Link copiado'
    
    navigator.clipboard.writeText(url);
}

</script>

<style scoped>

.btn-filter span{
    margin-left: 2px;
}

.filters_qty_active{
    background-color: #5986e2;
    padding: 0px 6px;
    font-size: 12px;
    color: white;
    border-radius: 40px;
    margin-left: 9px;
}

.btn-filter svg {
    margin-right: 7px;
}

.btn-filter::after {
    display: none !important;
}

small {
    display: block;
    color: #215eda;
    font-weight: 500;
    font-size: 80%;
    white-space: nowrap;
}

small span {
    cursor: pointer;
}

small span:hover {
    text-decoration: underline;
}

.light{
    color: #808080;
    font-weight: 400;
    cursor:text;
}

.light:hover{
    text-decoration: none;
}

</style>