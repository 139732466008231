<template>
    <form @submit.prevent="submit">
        <BaseInputCode
            id="code"
            v-model="register.code"
            :required="true"
            :maxlength="6"
            :page="route.name"
        />

        <BaseButton 
            class="mt-4 btn-primary w-100"
            :loading="isLoading"
            :disabled="isLoading">
            Continuar
        </BaseButton>
    </form>
</template>
<script setup>
import { ref, onMounted } from "vue";

import { useGlobalStore } from '@/stores/global';
import { useAuthStore } from '@/stores/auth';
import { useRouter, useRoute } from "vue-router";
import { storeToRefs } from 'pinia';

const authStore = useAuthStore();
const global = useGlobalStore();
const router = useRouter();
const route = useRoute();

const { register } = storeToRefs(authStore);

const isLoading = ref(false);



async function submit() {
    isLoading.value = false;

    // eslint-disable-next-line
    grecaptcha.ready(() => {
        isLoading.value = true;
        // eslint-disable-next-line
        grecaptcha.execute(window.RECAPTCHA_KEY, {action: 'submit'}).then(function(token) {
            authStore.register.recaptcha_token = token;
            let params = ['name', 'email', 'password', 'phone', 'cpf', 'birthdate', 'terms', 'code', 'recaptcha_token'];

            authStore.tryRegister(route.name, params).then(function(success) {
                isLoading.value = false;
                
                if(success){
                    useGlobalStore().cleanObject(authStore.register);
                    useGlobalStore().successMessage('Cadastro realizado com sucesso. Entre com seu login.');
                    router.push('/login')
                }
            });
        });
    });
}

onMounted(() => {
    delete global.errors[route.name];
});
</script>

<style scoped>
.form-check-label {
    vertical-align: middle;
    font-size: 13px;
    display: flex;
}
.form-check-input {
    width: 100%;
    height: 20px;
    max-width: 20px;
    margin-right: 10px;
}
</style>