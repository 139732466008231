<template>
    <div>
        <div class="row">
            <div class="box-infos col-12 mb-xl-0">
                <div class="box_content">
                <div class="row" @submit.prevent="">
                    <BaseInput
                        class="col-6"
                        label="Nome *"
                        id="title"
                        type="text"
                        :page="route.name"
                        v-model="product.title"
                    />
                    <BaseInput
                        class="col-6"
                        label="Descrição"
                        id="description"
                        type="text"
                        :page="route.name"
                        v-model="product.description"
                    />
                    <BaseInput
                        class="col-md-6 col-12"
                        label="Tag"
                        id="tag"
                        type="text"
                        :page="route.name"
                        v-model="product.tag"
                    />
                    <BaseInput
                        class="col-md-6 col-12"
                        label="Código(SKU)"
                        id="sku"
                        type="text"
                        :page="route.name"
                        v-model="product.sku"
                    />
                    <BaseSelect
                        class="col-md-4 col-12"
                        id="type"
                        :items="productType"
                        label="Tipo *"
                        placeholder="Selecione"
                        :page="route.name"
                        v-model="product.type"
                        :disabled="product.category_slug === null ? false : true"
                    />
                    <BaseSelect
                        class="col-md-4 col-12"
                        id="category_slug"
                        :items="productCategories"
                        label="Categoria *"
                        placeholder="Selecione"
                        :page="route.name"
                        v-model="product.category_slug"
                        :disabled="product.category_slug === null ? false : true"
                    />
                    <BaseInput
                        v-if="product.type == 'digital' && product.category_slug == 'course'"
                        id="course_title"
                        class="col-md-6 col-12"
                        type="text"
                        label="Nome do Curso *"
                        :page="route.name"
                        v-model="product.course_title"
                    />
                    <BaseInput
                        v-if="product.type == 'digital' && product.category_slug == 'course'"
                        id="course.duration"
                        class="col-md-6 col-12"
                        type="number"
                        label="Duração do curso *"
                        tooltip="Duração do curso em minutos"
                        :page="route.name"
                        v-model="product.course_duration"
                    />
                    <BaseDatePicker
                        v-if="product.type == 'digital' && product.category_slug == 'ticket'"
                        id="meta[ticket_date]"
                        class="col-md-4 col-12 mb-3"
                        label="Data do evento"
                        :page="route.name"
                        v-model="product.meta.ticket_date"
                        range
                        multi
                        timepicker
                        :close_autoapply="false"
                    />
                    <BaseInput
                        v-if="product.type == 'digital' && product.category_slug == 'ticket'"
                        id="meta[ticket_place]"
                        class="col-12"
                        textarea
                        label="Local do evento"
                        :page="route.name"
                        v-model="product.meta.ticket_place"
                    />
                    <BaseSelect
                        class="col-md-4 col-12"
                        id="status"
                        :items="productStatus"
                        label="Status "
                        placeholder="Selecione"
                        :page="route.name"
                        v-model="product.status"
                    />
                    <BaseInput
                        class="col-md-4 col-12"
                        id="qty"
                        label="Estoque"
                        type="number"
                        :page="route.name"
                        v-model="product.qty"
                    />
                    <BaseInput
                        class="col-md-4 col-12"
                        id="price"
                        type="text"
                        label="Valor *"
                        tag="R$"
                        :page="route.name"
                        v-model="product.price"
                        @input="updateInstallments()"
                        mask="9.99#,##"
                        :tokens="'9:[0-9]:repeated'"
                        :reversed="true"
                        maxlength="10"
                    />
                    <BaseInput
                        class="col-md-4 col-12"
                        id="price_promotional"
                        type="text"
                        label="Valor promocional"
                        @input="updateInstallments()"
                        tag="R$"
                        :page="route.name"
                        v-model="product.price_promotional"
                        mask="9.99#,##"
                        :tokens="'9:[0-9]:repeated'"
                        :reversed="true"
                        maxlength="10"
                    />
                    <BaseSelect
                        class="col-md-4 col-12"
                        id="installments"
                        :items="installmentsList"
                        label="Parcelamento"
                        type="text"
                        placeholder="Selecione"
                        :page="route.name"
                        v-model="product.installments"
                    />
                    <BaseSwitch
                        v-if="product.type === 'digital'"
                        class="mb-2"
                        label="Solicitar endereço na compra"
                        id="meta[request_address_on_digital]"
                        :page="route.name"
                        v-model:checked="product.meta.request_address_on_digital"
                    />
                    <template v-if="product.type == 'physical'">
                        <h6 class="mt-3">Volumes</h6>
                        <BaseInput
                            class="col-md-3 col-12"
                            id="qty_items"
                            label="Quantidade *"
                            type="number"
                            tooltip_text="Quantidade de itens que compõem esse produto"
                            :page="route.name"
                            v-model="product.qty_items"
                        />
                        <BaseInput
                            class="col-md-2 col-12"
                            id="weight"
                            type="number"
                            label="Peso"
                            tag="kg"
                            :page="route.name"
                            v-model="product.weight"
                        />
                        <BaseInput
                            class="col-md-2 col-12"
                            id="width"
                            type="number"
                            label="Largura"
                            tag="cm"
                            :page="route.name"
                            v-model="product.width"
                        />
                        <BaseInput
                            class="col-md-2 col-12"
                            id="height"
                            type="number"
                            label="Altura"
                            tag="cm"
                            :page="route.name"
                            v-model="product.height"
                        />
                        <BaseInput
                            class="col-md-3 col-12 mb-4"
                            id="length"
                            type="number"
                            label="Comprimento"
                            tag="cm"
                            :page="route.name"
                            v-model="product.length"
                        />
                    </template>
                    <BaseSwitch
                        id="allow_referral"
                        class="col-12 mt-0"
                        label="Permitir que afiliados tenham acesso a esse produto"
                        :page="route.name"
                        v-model:checked="product.allow_referral"
                    />
                    </div>

                    <template v-if="product.recurrent">
                    <div class="divisor_top"></div>
                    <div class="row align-items-end">
                        <h6>Recorrência</h6>
                        <BaseSwitch
                            class="my-lg-2 mt-3 mb-0"
                            label="Ativar recorrência"
                            id="recurrent"
                            :page="route.name"
                            v-model:checked="productStore.product.recurrent"
                        />

                        <template v-if="product.recurrent">
                            <BaseInput
                                class="col-md-4 col-12 mb-0"
                                id="recurrence_period.count"
                                type="number"
                                label="Período da recorrência*"
                                :page="route.name"
                                v-model="product.recurrence_period.count"
                            />
                            <BaseSelect
                                class="col-md-4 col-12 mb-0"
                                id="recurrence_period.interval"
                                :items="recurrenceInterval"
                                placeholder="Selecione"
                                :page="route.name"
                                v-model="product.recurrence_period.interval"
                            />
                            <small>*Com a recorrência ativa só serão realizadas vendas à vista com cartão de crédito.</small>
                        </template>
                    </div>
                    </template>

                    <div class="divisor_top"></div>

                    <div class="row">
                        <BaseFileUpload
                            class="col-12"
                            id="image"
                            label="Imagem do produto *"
                            type="file"
                            recommended_size="200 x 200"
                            :page="route.name"
                            v-model="product.image"
                            :preview="product.image"
                        />

                        <BaseFileUpload
                            class="col-12"
                            id="cover_desktop"
                            label="Banner superior"
                            type="file"
                            recommended_size="1250 x 400"
                            :showBadget="true"
                            badgetColor="#6f6f6f"
                            badgetText="Destkop"
                            :page="route.name"
                            v-model="product.cover_desktop"
                            :preview="product.cover_desktop"
                        />

                        <BaseFileUpload
                            class="col-12"
                            id="cover_mobile"
                            label="Banner superior"
                            type="file"
                            recommended_size="750 x 250"
                            :showBadget="true"
                            badgetColor="#6f6f6f"
                            badgetText="Mobile"
                            :page="route.name"
                            v-model="product.cover_mobile"
                            :preview="product.cover_mobile"
                        />

                        <BaseFileUpload
                            class="col-12"
                            id="banner"
                            label="Banner lateral"
                            type="file"
                            recommended_size="450 x 600"
                            :page="route.name"
                            v-model="product.banner"
                            :preview="product.banner"
                        />

                        <BaseFileUpload
                            class="col-12 box_end_inputs"
                            id="banner_content"
                            label="Banner formulário"
                            type="file"
                            recommended_size="750 x 150"
                            :page="route.name"
                            v-model="product.banner_content"
                            :preview="product.banner_content"
                        />
                    </div>

                    <div class="divisor_top"></div>

                    <div class="row">
                        <BaseInput
                            class="col-12 box_end_inputs"
                            id="external_thank_you_page"
                            label="URL de página de obrigado externa"
                            type="text"
                            :page="route.name"
                            v-model="product.external_thank_you_page"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router'
import { useGlobalStore } from '@/stores/global';
import { useProductStore } from '@/stores/products';

const route = useRoute();
const globalStore = useGlobalStore();

const productStore = useProductStore();
const { product } = storeToRefs(productStore);

const product_id = computed (function () {
    return route.params.id;
});

const productStatus = {
    'disabled': 'Desativado',
    'published': 'Publicado',
    'draft': 'Rascunho',
    'analyzing': 'Em análise',
}

const productType = ref({
  digital: 'Produto Digital',
  physical: 'Produto Físico',
})

const installmentsList = ref ({})
const productCategories = ref ({})
const recurrenceInterval = ref({
    'day': 'Dia(s)',
    'week': 'Semana(s)',
    'month': 'Mês(es)',
    'year': 'Ano(s)'
});

onBeforeMount(async () => {
    installmentsList.value = {}
    productCategories.value = {}
    if(productStore.product.id){
        for(let i = 0; i < productStore.all_categories.length; i++){
            productCategories.value[productStore.all_categories[i].slug] = productStore.all_categories[i].title;
        }
        for(let i = 0; i < productStore.all_installments.length; i++){
            installmentsList.value[i + 1] = productStore.all_installments[i] + 'x';
        }
        if(productStore.product.category_slug === 'ticket'){
            if(productStore.product.meta.ticket_date.length){
                if(productStore.product.meta.ticket_date[0] !== null && productStore.product.meta.ticket_date[1] !== null){
                    productStore.product.meta.ticket_date = productStore.product.meta.ticket_date[0] + '@' +productStore.product.meta.ticket_date[1];
                } else{
                    productStore.product.meta.ticket_date = null;
                }
            }
        }
    } else{
        productStore.tryProduct(product_id.value).then(() =>{productStore.product.price
            for(let i = 0; i < productStore.all_categories.length; i++){
                productCategories.value[productStore.all_categories[i].slug] = productStore.all_categories[i].title;
            }
            for(let i = 0; i < productStore.all_installments.length; i++){
                installmentsList.value[i + 1] = productStore.all_installments[i] + 'x';
            }
        })
    }
})

const params = ref({
    price: null,
})

let debounce = null;
function updateInstallments(){
    if(productStore.product.price_promotional){
        params.value.price = productStore.product.price_promotional;
    } else{
        params.value.price = productStore.product.price;
    }
    clearTimeout(debounce)
    debounce = setTimeout(() => {
        globalStore.loader('show');
        productStore.tryInstallments(product_id.value, params.value).then(() =>{
            globalStore.loader('hide')
        })
    }, 700)

}
</script>

<style scoped>

.box_end_inputs{
    margin-bottom: 0!important;
};
</style>